import { post } from '../../../common/apis/requestHelper';
import i18n from '../../../i18n';
import BACKEND_URLS from '../../../common/URLPaths';
import {
  checkArrType,
  checkBooleanType,
} from '../../../common/utils/FrontendTypeCheckers';

// ------ function to delete user's sentence. ----------
export const deleteSentence = async (sentenceId) => {
  const { t } = i18n;
  try {
    const rsp = await post(BACKEND_URLS.DEL_SENTENCE, {
      mySentenceId: sentenceId,
    });

    if (rsp && rsp.code == 0) {
      // setSentences(sentences.filter((item) => item.id != sentenceId));
      return true;
    } else {
      console.log('deleteSentence failed');
      window.showErrorModal(t('删除失败, 请重试'));
    }
  } catch (err) {
    console.log('deleteSentence failed');
    window.showErrorModal(t('删除失败, 请重试'));
  }
  return false;
};

// ------ function to stared user's sentence. ----------
export const starSentence = async (sentenceId, isStared) => {
  const { t } = i18n;
  try {
    const rsp = await post(BACKEND_URLS.STAR_SENTENCE, {
      mySentenceId: sentenceId,
      isStared,
    });

    if (rsp && rsp.code == 0) {
      return true;
    } else {
      console.log('starSentence failed');
      window.showErrorModal(t('收藏失败, 请重试'));
    }
  } catch (err) {
    console.log('starSentence failed');
    window.showErrorModal(t('收藏失败, 请重试'));
  }
  return false;
};
// -------------- learn one sentence   ----------------
export const learnSentence = (sentenceId, remember) => {
  const { t } = i18n;
  console.log('learnSentence', sentenceId, remember);
  if (!sentenceId || remember === undefined) {
    window.showErrorModal(t('参数错误'));
    return false;
  }

  post(BACKEND_URLS.LEARN_SENTENCE, {
    mySentenceId: sentenceId,
    isFamiliar: remember,
  })
    .then((rsp) => {
      if (rsp && rsp.code == 0) {
        console.log('learnSentence success');
        return true;
      } else {
        console.error('learnSentence failed');
        window.showErrorModal(t('接口报错，请联系管理员'));
      }
    })
    .catch((err) => {
      console.error('learnSentence failed');
      window.showErrorModal(err?.message || '系统异常, 请重试');
    });
};

// -------------- master one sentence   ----------------
export const masterSentence = async (sentenceId, mastered) => {
  const { t } = i18n;
  if (!sentenceId || mastered === undefined) {
    window.showErrorModal(t('参数错误'));
    return false;
  }

  checkBooleanType(mastered, 'mastered');

  try {
    const rsp = await post(BACKEND_URLS.MASTER_SENTENCE, {
      mySentenceId: sentenceId,
      mastered,
    });

    if (rsp && rsp.code == 0) {
      console.log('learnSentence success');
      return rsp?.data?.newMasterLevel;
    } else {
      console.error('learnSentence failed');
      window.showErrorModal(t('接口报错，请联系管理员'));
    }
  } catch (err) {
    console.error('learnSentence failed');
    window.showErrorModal(t('系统异常, 请重试'));
  }
};

// -------------- sync some result of test   ----------------
export const submitSentences = async (sentences, setId) => {
  const { t } = i18n;
  if (checkArrType(sentences) || !setId) {
    window.showErrorModal(t('参数错误'));
    return false;
  }

  try {
    const rsp = await post(BACKEND_URLS.LEARN_SENTENCES, {
      sentences,
      setId,
    });

    if (rsp && rsp.code == 0) {
      console.log('submitSentences success');
      return true;
    } else {
      console.error('submitSentences failed');
      window.showErrorModal(t('接口报错，请联系管理员'));
    }
  } catch (err) {
    console.error('submitSentences failed');
    window.showErrorModal(t('系统异常, 请重试'));
  }

  return false;
};

// ---------- export -------------
export default {
  starSentence,
  deleteSentence,
  learnSentence,
  submitSentences,
  masterSentence,
};
