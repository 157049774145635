/* eslint-disable no-unused-vars */
import React, { useRef, useEffect } from 'react';
// import classes from './Import.module.css';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import axios from 'axios';
import styles from './FileUploading.module.css';
import Spinner from 'react-bootstrap/Spinner';
//import infinite list.
import 'react-virtualized/styles.css';
import { List, AutoSizer } from 'react-virtualized';

import { useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
// import { useAuth0 } from '@auth0/auth0-react';
import CloseButton from 'react-bootstrap/CloseButton';
import Button from 'react-bootstrap/Button';
import { useAuth0 } from '@auth0/auth0-react';

import { post, postWithoutAuth } from '../../../common/apis/requestHelper';

import PropTypes from 'prop-types';
const MAX_FileSizeBytes = 2 * 1024 * 1024; // 将MB转换为字节

import {
  extractEnglishWords,
  addBlock,
} from '../../../common/utils/WordHelper.js';

import BACKEND_URLS from '../../../common/URLPaths';
import { CloudArrowUp, Icon0Circle } from 'react-bootstrap-icons';

let intervalId;
import { useTranslation } from 'react-i18next';

//由于ocr可能不准确 。 在这个界面，识别结果将会被呆板地返回到上层，之后传递到wordsinput, 让用户二次编辑识别结果。
function FileUploading(props) {
  const { t } = useTranslation();
  // let setId = props.setId;
  // if (typeof setId == 'string') setId = parseInt(setId);

  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);

  const { getAccessTokenSilently, user } = useAuth0();

  // ----- an interval task ------
  const retrieveOcrResult = async (taskId) => {
    try {
      const response = await post(BACKEND_URLS.OCR_RESULT, {
        taskId,
      });

      const { data, code } = response;

      if (code == 2) return; // this means the task is still in progress.

      if (code != 0) {
        window.showErrorModal(t('服务器错误') + ' code:' + code);
        setIsLoading(false);
        return;
      }
      clearInterval(intervalId);
      props.ocrCallback(data.text, data.words);
    } catch (error) {
      console.error(error);
      window.showErrorModal(
        error.response?.message || error.data?.message || t('异常')
      );

      clearInterval(intervalId);
      setIsLoading(false);
    }
  };

  //-------------------useEffect------------------
  useEffect(() => {
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, []);

  //----handleDisplayFileDetails---
  const handleDisplayFileDetails = () => {
    console.log(inputRef.current.files);

    const file = inputRef.current.files[0];
    if (!file) return;

    if (file.size > MAX_FileSizeBytes) {
      window.showErrorModal(t('文件大于2MB!'));
      return;
    }

    setIsLoading(true);
    // to upload file.
    const formData = new FormData();
    formData.append('file', file);
    axios
      .post(BACKEND_URLS.OCR_UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        const { data, code } = response;
        if (code != 0) {
          setIsLoading(false);
          window.showErrorModal(
            code == 2
              ? t('后台服务器OCR任务过多，请1分钟后重试。')
              : t('上传失败')
          );
          return;
        }
        console.log(data);

        if (data.taskId) {
          intervalId = setInterval(async () => {
            await retrieveOcrResult(data.taskId);
          }, 1500);
        }

        // props.ocrCallback(data.text, data.words);
      })
      .catch((error) => {
        // 处理上传失败的错误
        console.error(error);
        window.showErrorModal(t('上传失败'));
        setIsLoading(false);
        // 清除选择
        inputRef.current?.clear();
      });
  };

  return (
    <Container>
      <Card
        onClick={() => {
          // to prevent the click event from being triggered by the child element.
          if (isLoading) return;
          inputRef.current.click();
        }}
        className={styles.upload_control}
      >
        <Card.Body>
          {isLoading ? (
            <p className="d-flex  align-items-center  justify-content-center">
              <span className="text-warning">
                <Spinner animation="grow" size="sm" />
                {t('处理中...')}
              </span>
            </p>
          ) : (
            <p className="d-flex  align-items-center  justify-content-center">
              {t('上传图片')},<span className="text-muted">{t('最大2M')}</span>
            </p>
          )}

          <input
            ref={inputRef}
            onChange={handleDisplayFileDetails}
            className="d-none"
            type="file"
            accept="image/*"
          />
        </Card.Body>
        <Card.Header>
          <p className="d-flex  align-items-center  justify-content-center">
            <CloudArrowUp style={{ fontSize: '8em' }} />
          </p>
        </Card.Header>
      </Card>
    </Container>
  );
}

// props.type: ['image', 'pdf', 'folder']
FileUploading.propTypes = {
  setId: PropTypes.number,
  type: PropTypes.string,
  ocrCallback: PropTypes.func.isRequired,
};

export default FileUploading;
