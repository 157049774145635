import React from 'react';

import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="row justify-content-center">
        <div className="col-md-6 text-center">
          <h3 className="display-4">404 - {t('页面未找到')}</h3>
          <p className="lead">{t('抱歉，您访问的页面不存在。')}</p>
        </div>
      </div>
    </Container>
  );
};

export default NotFound;
