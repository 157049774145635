import React, { useEffect } from 'react';
import { FaLanguage } from 'react-icons/fa';
// import classes from './Topbar.module.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';

import { motion } from 'framer-motion';
// look for Icons from https://icons.getbootstrap.com/
import * as Icon from 'react-bootstrap-icons';

import { useAuth0 } from '@auth0/auth0-react';
import { removeUser } from '../../common/apis/UserInfoHelper';

import { useTranslation } from 'react-i18next';
import { setLanguage } from '../../common/utils/LanguageHelper';

import i18n from '../../i18n';
function TopBar() {
  const { loginWithRedirect } = useAuth0();
  // read username from auth0 {loginWithRedirect, logout,}
  const { isAuthenticated, user, logout } = useAuth0();

  const { t } = useTranslation();

  const logoutFun = () => {
    logout({ logoutParams: { returnTo: window.location.origin } }).then(() => {
      console.log('signed out.');
      removeUser();
    });
  };

  // const [isLogoutPopShowing, setShowingLogoutPop] = useState(false);
  const renderMineSubMenu = (props) => (
    <Tooltip id="username-tooltip" {...props}>
      <ListGroup>
        <Link to="/mine/import">
          <ListGroup.Item>{t('导入生词')}</ListGroup.Item>
        </Link>
        <Link to="/mine/homework">
          <ListGroup.Item>{t('我的学习集')}</ListGroup.Item>
        </Link>
        <ListGroup.Item onClick={logoutFun}>{t('登出')}</ListGroup.Item>
      </ListGroup>
    </Tooltip>
  );

  const onChangeLanguage = (lan) => {
    setLanguage(lan);
    i18n.changeLanguage(lan);
  };

  const renderLanSubMenu = (props) => (
    <Tooltip id="username-tooltip" {...props}>
      <ListGroup>
        <ListGroup.Item
          onClick={() => {
            onChangeLanguage('en');
          }}
        >
          English
        </ListGroup.Item>
        <ListGroup.Item
          onClick={() => {
            onChangeLanguage('zh');
          }}
        >
          中文
        </ListGroup.Item>
      </ListGroup>
    </Tooltip>
  );

  useEffect(() => {}, []);

  return (
    <Navbar className="sticky_nav">
      <Container>
        <motion.div whileHover={{ scale: 1.2 }}>
          <Navbar.Brand style={{}} href="/">
            Scitudy
          </Navbar.Brand>
        </motion.div>

        <motion.div whileHover={{ scale: 1.2 }}>
          <Navbar.Brand style={{}} href="https://blog.scitudy.com/download">
            {t('app_download')}
          </Navbar.Brand>
        </motion.div>

        <motion.div whileHover={{ scale: 1.2 }}>
          <Navbar.Brand
            style={{}}
            href="https://blog.scitudy.com/function-introduction/"
          >
            {t('title_help')}
          </Navbar.Brand>
        </motion.div>

        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {/* 显示一个 AI入口 */}
          <Link to="/mine/ai-teacher">
            <motion.div whileHover={{ scale: 1.2 }} className="p-1">
              <Icon.Mortarboard color="white" style={{ marginRight: '1em' }} />
            </motion.div>
          </Link>

          {/* 一个入口：词库导入  */}
          <Link to="/lib_import">
            <motion.div whileHover={{ scale: 1.2 }} className="p-1">
              <Icon.DatabaseAdd color="white" style={{ marginRight: '1em' }} />
            </motion.div>
          </Link>

          {user && isAuthenticated ? (
            // signed in.
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderMineSubMenu}
              defaultShow={false}
            >
              <motion.div whileHover={{ scale: 1.2 }}>
                <Navbar.Text onClick={() => {}}>{t('我的')}</Navbar.Text>
              </motion.div>
            </OverlayTrigger>
          ) : (
            //signed out.
            <Navbar.Text
              onClick={() => {
                loginWithRedirect();
              }}
              style={{
                marginRight: '10px',
                fontSize: 'medium',
              }}
            >
              <motion.div whileHover={{ scale: 1.2 }}>{t('登录')}</motion.div>
            </Navbar.Text>
          )}
        </Navbar.Collapse>

        {/* language */}
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderLanSubMenu}
          defaultShow={false}
        >
          <motion.div whileHover={{ scale: 1.2 }} className="p-1">
            <span>
              <FaLanguage
                style={{
                  color: 'white',
                  fontSize: '24px',
                  marginLeft: '0.5em',
                }}
              />
            </span>
          </motion.div>
        </OverlayTrigger>
      </Container>
    </Navbar>
  );
}

export default TopBar;
