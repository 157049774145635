/* eslint-disable no-unused-vars */
import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { checkNumberType } from '../../../common/utils/FrontendTypeCheckers';

// A Helper class with functions to create a progress bar component to display the learning process of a study set.

/**
 * Creates a progress bar component to display the learning process of a study set.
 * @param {number} process - The current progress of the learning process.
 * @param {number} total - The total number of items in the study set.
 * @returns {JSX.Element} - A progress bar component.
 */
export const createInProcess = (process, total) => {
  checkNumberType(process);
  checkNumberType(total);
  const now = Math.floor((process * 100) / total);
  return <ProgressBar striped variant="warning" now={now} />;
};

export const createMastered = (process, total) => {
  checkNumberType(process);
  checkNumberType(total);

  const now = Math.floor((process * 100) / total);
  return <ProgressBar now={now} variant="success" />;
};

export default {
  createInProcess,
  createMastered,
};
