/* eslint-disable no-unused-vars */
import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { FlashcardArray } from 'react-quizlet-flashcard';
import { Link } from 'react-router-dom';
//import infinite list.
import 'react-virtualized/styles.css';
import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import ReactCardFlip from 'react-card-flip';
import styles from './StudySet.module.css';
import { useParams, useLocation, useHistory } from 'react-router-dom';
// import auth0
import { useAuth0 } from '@auth0/auth0-react';
import { isPagerComplete } from '../../../common/utils/WebPagerHelper';
import { Card, Col, Row } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { createMastered, createInProcess } from './LearningProcessHelper';
import {
  starSentence,
  deleteSentence,
  learnSentence,
  masterSentence,
} from './StudySetDetailAPI';

import * as Icon from 'react-bootstrap-icons';

import BACKEND_URLS from '../../../common/URLPaths';

// to import Spinner
import Spinner from 'react-bootstrap/Spinner';
import { post, showingResponseError } from '../../../common/apis/requestHelper';
import {
  readTokenWithDialog,
  readUser,
} from '../../../common/apis/UserInfoHelper';
import RoutePaths from '../../../RoutePaths';
import { speech } from '../../../common/utils/TTSUtils';
import CustomFlashcard from '../Flashcard/CustomFlashCard';
import {
  checkNumberType,
  checkStringType,
} from '../../../common/utils/FrontendTypeCheckers';
import { useTranslation } from 'react-i18next';

const LIMITATION_OF_IMPORTS = 100;

// --------- StudySetDetail  ---------
function StudySetDetail() {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const { setId } = params;
  if (!setId) {
    alert('setId is not defined');
    return <div />;
  }

  const { getAccessTokenSilently } = useAuth0();
  const [pagerLoading, setPagerLoading] = useState(true);
  const [listDetailModelRsp, setListDetailModelRsp] = useState(null);

  const [flippedIndex, setFlippedIndex] = useState(-1);

  const [innerWidth, setInnerWidth] = useState(1000);
  const [total, setTotal] = useState(0);
  const [inProgress, setInProgress] = useState(0);
  const [mastered, setMastered] = useState(0);
  const [type, setType] = useState(0); // 1: word, 2: phrase , type of study set.

  // some states about array.
  const [arrayLoading, setArrayLoading] = useState(true);
  const [sentences, setSentences] = useState(undefined);
  const [markedArr, setMarkedArr] = useState(undefined);
  /* item in this array below should be like this:
  {   id: 7,
      frontHTML: <>What is the capital of Arizona?</>,
      backHTML: <>Phoenix</>,
    },
    */
  const [cards, setCards] = useState(undefined);

  const createSentenceCol = (sentence, index) => {
    const meaning =
      window.language == 'zh' ? sentence.cnMeaning : sentence.enMeaning;

    const frontContent = (
      <div className={styles.word_item_sentence}>
        <span>{sentence.text || 'error'}</span>
      </div>
    );
    if (!meaning) {
      return frontContent;
    }

    return (
      <ReactCardFlip isFlipped={flippedIndex == index} flipDirection="vertical">
        {frontContent}

        <div className={styles.word_item_sentence}>
          <span>{(sentence.POS || '') + '  ' + meaning}</span>
        </div>
      </ReactCardFlip>
    );
  };

  const createWordItemCpnt = (sentence, indexOfS) => {
    checkNumberType(indexOfS, 'indexOfS');
    const onClickStar = async () => {
      sentence.isLoading = true;
      setSentences([...sentences]);
      const result = await starSentence(sentence.id, !sentence.isStared);
      if (result) {
        sentence.isLoading = false;
        sentence.isStared = !sentence.isStared;
        setSentences([...sentences]);
      }
    };

    const keyPrefix = 'sentence_item_' + sentence.id;

    const { word } = sentence.Word || {};
    const { text } = sentence.Sentence || {};
    const phrase = sentence.Phrase?.text;

    return (
      <Row className={styles.word_item} key={keyPrefix}>
        <Col
          md={2}
          onClick={() => {
            speech(word || phrase || 'error');
          }}
        >
          <div className={styles.word_item_word}>
            <span>{word || phrase || 'error'}</span>
          </div>
        </Col>

        <Col
          md={8}
          onClick={() => {
            if (sentence.Sentence) {
              sentence.Sentence.isFlipped = !sentence.Sentence.isFlipped;
              setFlippedIndex(indexOfS);
            }
          }}
        >
          {createSentenceCol(sentence.Sentence, indexOfS)}
        </Col>

        <Col md={2}>
          <div>
            {sentence.isLoading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                className="align-self-center"
              />
            ) : (
              <span className={styles.word_item_end}>
                {sentence.isStared ? (
                  <Icon.StarFill
                    key={keyPrefix + '_star1'}
                    className={styles.word_item_icon}
                    color="#f39128"
                    onClick={onClickStar}
                  />
                ) : (
                  <Icon.Star
                    key={keyPrefix + '_star2'}
                    className={styles.word_item_icon}
                    color="#DDD"
                    onClick={onClickStar}
                  />
                )}

                {/* checked symbol */}
                {'  '}
                <Icon.Check2All
                  className={styles.word_item_icon}
                  key={keyPrefix + '_master'}
                  color={sentence.masterLevel > 95 ? '#f39128' : '#DDD'}
                  onClick={async () => {
                    sentence.isLoading = true;
                    setSentences([...sentences]);
                    const level = await masterSentence(
                      sentence.id,
                      sentence.masterLevel < 96
                    );
                    if (level) {
                      if (typeof level == 'number') {
                        sentence.masterLevel = level;
                      }
                    }
                    sentence.isLoading = false;
                    setSentences([...sentences]);
                  }}
                />

                {'  '}
                <Icon.Trash3
                  className={styles.word_item_icon}
                  key={keyPrefix + '_del'}
                  color="#d11a2a"
                  onClick={async () => {
                    sentence.isLoading = true;
                    setSentences([...sentences]);
                    if (await deleteSentence(sentence.id)) {
                      sentence.isLoading = false;
                      setSentences(
                        sentences.filter((item) => item.id != sentence.id)
                      );
                    }
                  }}
                />
              </span>
            )}
          </div>
        </Col>
      </Row>
    );
  };

  const createSpanWrapper = (component) => {
    return (
      <Card className={styles.studyset_card}>
        <Card.Body>{component}</Card.Body>
      </Card>
    );
  };

  const requestSentences = async (setId, t) => {
    const showErrorModal = window.showErrorModal;

    const requestUrl =
      t == 1
        ? BACKEND_URLS.WORD_SENTENCES_PATH
        : BACKEND_URLS.PHRASE_SENTENCES_PATH;
    //load list detail
    post(requestUrl, {
      setId,
    })
      .then((rsp) => {
        console.log('rsp: ' + JSON.stringify(rsp));
        if (rsp && rsp.data && rsp.code == 0) {
          setSentences(rsp.data);
          const tempCards = [];
          rsp.data.map((item) => {
            let phrase = undefined;
            {
              const { text } = item.Phrase || {};
              phrase = text;
            }

            const { text } = item.Sentence || {};
            const { word } = item.Word || {};

            tempCards.push({
              id: item.id,
              frontHTML: <>{text || 'error'}</>,
              backHTML: <>{word || phrase || 'error'}</>,
            });
          });
          console.log('cards len: ' + tempCards.length);
          setCards(tempCards);
        } else {
          showErrorModal(t('获取列表失败'));
        }
        setArrayLoading(false);
      })
      .catch((error) => {
        console.error('rsp: errs ');
        !showingResponseError && showErrorModal(t('获取列表失败'));
        setArrayLoading(false);
      });
  };

  // 定义宽度变化时的处理逻辑
  const handleResize = () => {
    const element = document.getElementById('app_container');
    // to set the width of the list.
    let width = element.offsetWidth;
    if (width < 10) {
      width = 1000;
    }
    const sidePadding = 15;
    setInnerWidth(width - sidePadding * 2);
  };

  useEffect(() => {
    // 添加事件监听器
    window.addEventListener('resize', handleResize);

    if (isPagerComplete() && setId) {
      handleResize();
      const showErrorModal = window.showErrorModal;
      readTokenWithDialog(getAccessTokenSilently)
        .then((token) => {
          if (!token) {
            showErrorModal(t('请重新登陆'));
            return;
          }
        })
        .catch((err) => {
          showErrorModal(t('请重新登陆'));
        });

      //load list detail
      post(BACKEND_URLS.MY_STUDYSET_TDETAIL_PATH, {
        setId,
      })
        .then((rsp) => {
          setPagerLoading(false);
          if (rsp && rsp.data && rsp.code == 0) {
            const { inProgress, total, mastered, marked, unlearnt } = rsp.data;
            const { type } = rsp.data.listInfo;
            setType(type);
            setInProgress(inProgress);
            setTotal(total);
            setListDetailModelRsp(rsp.data);

            setTimeout(async () => {
              await requestSentences(setId, type);
            });
          } else {
            showErrorModal(t('获取数据失败 code: ') + rsp && rsp.code);
          }
        })
        .catch((error) => {
          setPagerLoading(false);
          const { code, status, message } = error;
          const stat = code || status;
          console.error('rsp: ' + JSON.stringify(error));
          if (stat == 404 || (message + '').includes('404')) {
            !showingResponseError && showErrorModal(t('学习集不存在'));
          } else {
            !showingResponseError && showErrorModal(t('获取数据失败'));
          }
        });
    }

    // 组件销毁时移除事件监听器
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (pagerLoading) {
    return (
      <div className="center_child">
        <Spinner animation="border" role="status" size="2x" />
      </div>
    );
  }

  const listName = listDetailModelRsp?.listInfo?.listName;

  return (
    <div>
      <Container style={{ textAlign: 'center' }}>
        <Col>
          {/* title */}
          <Row>
            <Col md={8}>
              <div style={{ display: 'flex' }}>
                <h3>{listName}</h3>
              </div>
            </Col>

            <Col md={4}>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'end',
                  justifyContent: 'end',
                }}
              >
                <div className="px-2">
                  <Button
                    variant="outline-light"
                    onClick={() => {
                      console.log(
                        'onClick listDetailModelRsp: ' + listDetailModelRsp
                      );

                      if (total > LIMITATION_OF_IMPORTS) {
                        window.showErrorModal(t('超出限制，请新建学习集。'));
                        return;
                      }
                      history.push(
                        RoutePaths.mergeImport(
                          setId,
                          listDetailModelRsp.listInfo.type
                        )
                      );
                      if (listDetailModelRsp && listDetailModelRsp.listInfo) {
                        history.push(
                          RoutePaths.mergeImport(
                            setId,
                            listDetailModelRsp.listInfo.type
                          )
                        );
                      }
                    }}
                  >
                    <Icon.PlusSquareFill
                      color="#DDD"
                      style={{ marginRight: '1em' }}
                    />
                    {t('去导入')}
                  </Button>
                </div>

                <Button
                  variant="outline-light"
                  onClick={() => {
                    history.push(RoutePaths.editList(setId));
                  }}
                >
                  <Icon.PencilSquare
                    color="#DDD"
                    style={{ marginRight: '1em' }}
                  />
                  {t('编辑')}
                </Button>
              </div>
            </Col>
          </Row>

          {/* 3 buttons */}
          <Row>
            <Col
              onClick={() => {
                history.push('/flashcard/' + setId);
              }}
            >
              {createSpanWrapper(
                <span className={styles.center_child}>
                  <Icon.Union color="white" style={{ marginRight: '1em' }} />
                  {t('闪卡')}
                </span>
              )}
            </Col>
            <Col
              onClick={() => {
                history.push('/learn/' + setId);
              }}
            >
              {createSpanWrapper(
                <span className={styles.center_child}>
                  <Icon.Stars color="white" style={{ marginRight: '1em' }} />
                  {t('学习')}
                </span>
              )}
            </Col>
            <Col
              onClick={() => {
                history.push('/listening/' + setId);
              }}
            >
              {createSpanWrapper(
                <span className={styles.center_child}>
                  <Icon.Controller
                    color="white"
                    style={{ marginRight: '1em' }}
                  />
                  {t('听力')}
                </span>
              )}
            </Col>
            <Col>
              {createSpanWrapper(
                <span className={styles.center_child}>
                  <Icon.Controller
                    color="white"
                    style={{ marginRight: '1em' }}
                  />
                  {t('测试')}
                </span>
              )}
            </Col>
          </Row>

          {/* flashcard */}
          {arrayLoading ? (
            <Spinner animation="border" role="status" />
          ) : (
            <CustomFlashcard
              sentences={sentences}
              innerWidth={innerWidth}
            ></CustomFlashcard>
          )}

          <hr />

          {/* progress */}
          <Row>
            <div style={{ display: 'flex' }}>
              <h3>{t('当前进度')}</h3>
            </div>
          </Row>

          <Row>
            <ProgressBar>
              {/* <ProgressBar striped variant="danger" now={33} key={'unlearnt'} /> */}

              {createMastered(mastered, total)}
              {createInProcess(inProgress, total)}
            </ProgressBar>
          </Row>
          <div className="spacer" />
          <Row>
            <Col>
              {createSpanWrapper(
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {t('掌握')}{' '}
                  {listDetailModelRsp ? listDetailModelRsp.mastered : 0}
                </span>
              )}
            </Col>
            <Col>
              {createSpanWrapper(
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {t('在学')}{' '}
                  {listDetailModelRsp ? listDetailModelRsp.inProgress : 0}
                </span>
              )}
            </Col>
            <Col>
              {createSpanWrapper(
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {t('未学')}{' '}
                  {listDetailModelRsp ? listDetailModelRsp.unlearnt : 0}
                </span>
              )}
            </Col>
          </Row>

          {/* to render words as a list */}

          {sentences && sentences.length > 0 ? (
            sentences.map((item, indexOfS) => {
              return createWordItemCpnt(item, indexOfS);
            })
          ) : (
            <div style={{ display: 'flex' }}>
              <span>{t('暂无数据')}</span>
            </div>
          )}

          {/* divider + dash line */}
          <div className="spacer" />
        </Col>
      </Container>
    </div>
  );
}

export default StudySetDetail;
