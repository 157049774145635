/* eslint-disable no-unused-vars */
import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { FlashcardArray } from 'react-quizlet-flashcard';
import { Link } from 'react-router-dom';
//import infinite list.
import 'react-virtualized/styles.css';
import PropTypes from 'prop-types';

import { fetchTextAudio, speech } from '../../../common/utils/TTSUtils';
import { Card, Col, Row } from 'react-bootstrap';

import { getSentenceCacheManager } from '../utils/ManagerGetter';

import {
  starSentence,
  deleteSentence,
  learnSentence,
} from '../StudySet/StudySetDetailAPI';

import * as Icon from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import {
  checkObjectType,
  checkStringType,
} from '../../../common/utils/FrontendTypeCheckers';

import { motion, useAnimation } from 'framer-motion';
import SentenceHighlighter from '../../../common/ui/SentenceHighlighter';
import Nodata from '../Clips/NoData';

import {
  getFeatureEnable,
  OPT_FEATURES,
  setFeatureEnable,
} from './FullscreenFlashcardOptionsStorage';

let correctCount = 0;
// --------- CustomFlashcard  ---------
function CustomFlashcard(props) {
  const { t } = useTranslation();
  const [flashcardIndex, setFlashcardIndex] = useState(1);
  const controlRef = useRef({}); // {} should definitely be passed to useRef for it to work
  const currentCardFlipRef = useRef(); // nothing should be passed to useRef for it to work

  const [cards, setCards] = useState(undefined);
  const { innerWidth, onFlipped, sentences } = props;

  const controlsIconX = useAnimation();
  const controlsIconChecked = useAnimation();

  const unfamiliar = () => {
    if (!sentences) return;

    {
      const id =
        sentences[flashcardIndex - 1]?.id ||
        sentences[flashcardIndex - 1]?.content?.id;
      if (id && id > 0) {
        learnSentence(id, false);
        getSentenceCacheManager().recordFailure(id);
      }
    }
    controlsIconX.start({
      scale: 1.2,
    });
    setTimeout(() => {
      controlsIconX.start({
        scale: 1,
      });
    }, 250);

    controlRef.current.nextCard();
    if (flashcardIndex == sentences.length) {
      props.onFinished && props.onFinished(correctCount);
    }
  };
  const know = () => {
    if (!sentences) return;
    console.log('know: ' + flashcardIndex + ' ' + sentences.length);
    {
      const id =
        sentences[flashcardIndex - 1]?.id ||
        sentences[flashcardIndex - 1]?.content?.id;
      if (id && id > 0) {
        console.log('know: learnSentence ');
        learnSentence(id, true);
        getSentenceCacheManager().recordSuc(id);
      }
    }

    controlsIconChecked.start({
      scale: 1.2,
    });
    setTimeout(() => {
      controlsIconChecked.start({
        scale: 1,
      });
    }, 250);
    controlRef.current.nextCard();
    correctCount++;
    if (flashcardIndex == sentences.length) {
      props.onFinished && props.onFinished(correctCount);
    }
  };

  const onKeyDown = (event) => {
    // console.log('event: ' + event.key + '  ' + event.altKey + '  ' + event.ctrlKey + '  ' + event.shiftKey);
    const { key, altKey, ctrlKey, shiftKey } = event;
    if (altKey == true || ctrlKey == true || shiftKey == true) {
      return;
    }
    const useKeyboard = getFeatureEnable(OPT_FEATURES.KEYBOARD, true);
    if (!useKeyboard) return; //exist if useKeyboard is false

    if (['ArrowUp', 'e'].includes(key)) {
      event.preventDefault();
      controlRef.current && controlRef.current.prevCard();
    } else if (['ArrowDown', 's'].includes(key)) {
      event.preventDefault();
      controlRef.current?.nextCard();
    } else if (['ArrowLeft', 'a'].includes(key)) {
      event.preventDefault();
      unfamiliar();
    } else if (['ArrowRight', 'd'].includes(key)) {
      event.preventDefault();
      console.log('know key down.');
      know();
    } else if ([' '].includes(key)) {
      event.preventDefault();
      currentCardFlipRef.current(); //
    }
  };

  /**
   *
   * @param {object} item
   * @returns {object} {id, frontHTML, backHTML}
   */
  function createSentenceJSX(item) {
    checkObjectType(item);
    // console.log('createSentenceJSX: ' + JSON.stringify(item));
    const { text, POS } = item.Sentence;
    const { word } = item.Word || {}; //
    let phrase = item.Phrase?.text;
    let meaning =
      window.language == 'zh'
        ? item.Sentence.cnMeaning
        : item.Sentence.enMeaning;

    // console.log(
    //   'createSentenceJSX: ' + ' ' + (word || phrase) + ' ' + meaning + ' ' + POS
    // );
    if (
      meaning &&
      (word || phrase) &&
      meaning.trim().toLowerCase() == (word || phrase).trim().toLowerCase()
    ) {
      meaning = undefined;
    }

    // TODO show translation fo the sentence in front content.
    return {
      id: item.id,
      frontHTML: (
        <SentenceHighlighter
          sentence={text || 'error'}
          word={word || 'error'}
        />
      ),
      backHTML: (
        <div className="flex-column align-items-center justify-content-center">
          {word || phrase || 'error'}

          <p className="mt-1 px-2 border border-secondary rounded text-secondary">
            {(POS || '') +
              (meaning && getFeatureEnable(OPT_FEATURES.MEANING, true)
                ? ` ${meaning}`
                : '')}
          </p>
        </div>
      ),
    };
  }

  /**
   * @param {string} item
   * @returns {object} {id, frontHTML, backHTML}
   */
  function createWordListeningCard(item) {
    if (item) {
      checkStringType(item);
    }
    const ERR_PLACEHOLDER = '**error**';
    const word = item || ERR_PLACEHOLDER;
    return {
      id: -100,
      frontHTML: (
        <>
          <Icon.VolumeUp
            onClick={(e) => {
              e.stopPropagation();
              if (ERR_PLACEHOLDER != word) {
                speech(word);
              }
            }}
            style={{ fontSize: '5em' }}
            color="#7532f9"
          />
        </>
      ),
      backHTML: <div>{word}</div>,
    };
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    const tempCards = [];
    if (sentences) {
      sentences.map((item) => {
        if (!item.id && item.content) {
          // item.content 是 parseResult4FlashcardMaterials#造的数据.
          if (item.type == 'sentence') {
            tempCards.push(createSentenceJSX(item.content));
          } else if (['word', 'phrase'].includes(item.type)) {
            tempCards.push(createWordListeningCard(item.content));
          }
        } else {
          tempCards.push(createSentenceJSX(item));
        }
      });
      setCards(tempCards);
      correctCount = 0;

      const tmpMaterials = sentences.filter((item) => {
        return item.type == 'word';
      });
      if (tmpMaterials.length < 10) {
        tmpMaterials.forEach((item) => {
          fetchTextAudio(item.content);
        });
      }
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [sentences, flashcardIndex]);

  return (
    <div
      style={{
        width: innerWidth + 'px',
      }}
    >
      {/* flashcard */}
      <div
        style={{
          width: innerWidth + 'px',
          height: innerWidth / 2 + 'px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() =>
          currentCardFlipRef.current && currentCardFlipRef.current()
        }
      >
        {cards && cards.length > 0 ? (
          <FlashcardArray
            style={{
              width: innerWidth + 'px',
              height: innerWidth / 2 + 'px',
            }}
            controls={false}
            showCount={false}
            forwardRef={controlRef}
            currentCardFlipRef={currentCardFlipRef}
            backContentStyle={{
              backgroundColor: '#333',
              color: 'white',
              padding: '1em',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '2rem',
              width: innerWidth + 'px',
              height: innerWidth / 2 + 'px',
              // gridTemplateColumns: "repeat(3, 1fr)",
              // gridTemplateRows: "repeat(3, 1fr)",
            }}
            frontContentStyle={{
              backgroundColor: '#282c34',
              color: '#EEE',
              display: 'grid',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '1em',
              // gridTemplateColumns: "repeat(3, 1fr)",
              // gridTemplateRows: "repeat(3, 1fr)",
              fontSize: '1.2em',
              width: innerWidth + 'px',
              height: innerWidth / 2 + 'px',
            }}
            cards={cards}
            onCardChange={(id, index) => {
              console.log('onCardChange: ' + id + ' ' + index);
              setFlashcardIndex(index);
            }}
            onCardFlip={(id, index, state) => {
              onFlipped && onFlipped(index);
            }}
          />
        ) : (
          <Nodata />
        )}
      </div>

      <div className="spacer"></div>

      {/* flashcard control */}
      {cards && cards.length > 0 ? (
        <Row>
          <div
            style={{
              display: 'flex',
              fontSize: '2em',
              justifyContent: 'center',
            }}
          >
            <motion.div animate={controlsIconX}>
              <Icon.XCircleFill
                color="#d11a2a"
                style={{ fontSize: '1.5em' }}
                onClick={unfamiliar}
              />
            </motion.div>

            <span style={{ marginLeft: '0.5em', marginRight: '0.5em' }}>
              {flashcardIndex + '/' + cards.length}
            </span>

            <motion.div animate={controlsIconChecked}>
              <Icon.CheckCircleFill
                color="green"
                style={{ fontSize: '1.5em' }}
                onClick={know}
              />
            </motion.div>
          </div>
        </Row>
      ) : null}
    </div>
  );
}

CustomFlashcard.propTypes = {
  sentences: PropTypes.array,
  innerWidth: PropTypes.number.isRequired,
  onFinished: PropTypes.func,
  onFlipped: PropTypes.func,
};
export default CustomFlashcard;
