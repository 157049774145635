/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
// import classes from './Import.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
// to import Spinner
import Spinner from 'react-bootstrap/Spinner';
import { post, showingResponseError } from '../../common/apis/requestHelper';

// import auth0
import { useAuth0 } from '@auth0/auth0-react';
import { readTokenWithDialog } from '../../common/apis/UserInfoHelper';
import BACKEND_URLS from '../../common/URLPaths';
import { useTranslation } from 'react-i18next';

import StudySetTypeSelModal from './StudySetTypeSelModal';

// eslint-disable-next-line no-unused-vars
function CreateList(props) {
  const { t } = useTranslation();
  // it is a form to submit, so it need to show loading.
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState(props.type || 0); // 1: word, 2: sentence, 0 means unset.

  const { getAccessTokenSilently, user } = useAuth0();

  const history = useHistory();

  async function onSubmitStudySetInfo(event) {
    console.log('onSubmitStudySetInfo');

    const showErrorModal = window.showErrorModal;
    const title = event.target.elements['form.title'].value;
    const schoolname = event.target.elements['form.schoolname'].value;
    const desc = event.target.elements['form.desc'].value;

    setIsLoading(true);
    let token = '';
    readTokenWithDialog(getAccessTokenSilently);

    post(BACKEND_URLS.SET_CREATION_PATH, {
      title,
      schoolname,
      desc,
      type,
    })
      .then((response) => {
        setIsLoading(false);
        // console.log(response);
        if (response.code == 0) {
          console.log('创建成功' + response.data.id);
          // history.push('/mine/import?setId=' + response.data.id);
          props.creatingStudySetCallback(response.data.id, type);
        } else {
          !showingResponseError && showErrorModal(t('创建失败'));
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);

        !showingResponseError && showErrorModal(t('数据异常，请稍后重试。'));
      });

    // return alert(
    //   `Entered Values are: ${title}, schoolname: ${schoolname}, desc: ${desc}`
    // );
  }

  // in case type ==0, create a bootstrap <Modal> for user to select type[1, 2], which means word or sentence.
  if (!type || type == 0) {
    return (
      <div>
        <StudySetTypeSelModal
          onChose={(type) => {
            setType(type);
            if (type == 0) {
              // web browser back to previous page.
              history.goBack();
            }
          }}
        />
      </div>
    );
  }

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        setTimeout(() => {
          onSubmitStudySetInfo(event);
        });
      }}
    >
      <Container>
        <h3>{t(type == 1 ? '创建单词集' : '创建词组集')}</h3>
        <div className="spacer" />
        <Col>
          <Row>
            <Form.Group controlId="form.title">
              <Form.Control type="title" placeholder={t('标题')} />
            </Form.Group>
          </Row>
          <div className="spacer" />

          <Row>
            <Form.Group controlId="form.schoolname">
              <Form.Control type="school" placeholder={t('学校(可选)')} />
            </Form.Group>
          </Row>

          <div className="spacer" />

          <Row>
            <Form.Group controlId="form.desc">
              <Form.Control
                as="textarea"
                rows="3"
                type="desc"
                placeholder={t('描述(可选)')}
              />
            </Form.Group>
          </Row>
          <div className="spacer" />

          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? (
              <div>
                {t('创建')} <Spinner animation="border" size="sm" />
              </div>
            ) : (
              t('创建')
            )}
          </Button>
        </Col>
      </Container>
    </Form>
  );
}

CreateList.propTypes = {
  creatingStudySetCallback: PropTypes.func,
  type: PropTypes.number.isRequired, //从 Import.jsx 传入的 type, 可能为0
};

export default CreateList;
