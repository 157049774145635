import { checkObjectType } from '../../../common/utils/FrontendTypeCheckers';

import { getSentenceCacheManager } from '../utils/ManagerGetter';
// it should be over 5 at least.
const LEN_OF_RESULT = 30;

/**
 * 从数据库中获取的数据，需要经过这个函数处理，才能用于前端显示。
 * 这里提取句子或者word的逻辑为：
 * 1. 从48H之内的数据里取一些错过的词汇。
 * 2. 如果48H之内的数据不够，那么从其他数据里取一些词汇。
 */

/**
 * @param result be like:
 * {
 *      words; //string[]
 *      sentencesIn48Hours; //sentences[]
 *      otherSentences; //sentences[]
 * }
 * @returns Array like:[ {type: 'word', content: 'word'}, {type: 'sentence', content: 'sentence'}}]
 */
export function parseResult4FlashcardMaterials(result) {
  checkObjectType(result);
  const { words, sentencesIn48Hours, otherSentences } = result;
  const flashcardMaterial = [];

  if (sentencesIn48Hours && sentencesIn48Hours.length > 0) {
    sentencesIn48Hours.forEach((sentence) => {
      if (!sentence.id) throw new Error('sentence.id is not provided.');
      if (getSentenceCacheManager().getFailureCount(sentence.id) > 1) {
        flashcardMaterial.push({ type: 'sentence', content: sentence });
      } else if (getSentenceCacheManager().getSucCount(sentence.id) == 0) {
        flashcardMaterial.push({ type: 'sentence', content: sentence });
      }
    });
  }

  // use this variable to limit the count of words.
  let countOfWords = 0;
  if (flashcardMaterial.length < LEN_OF_RESULT) {
    if (words) {
      words.forEach((word) => {
        const radomInt = Math.floor(Math.random()); //put word randomly.
        if (
          countOfWords < LEN_OF_RESULT / 3 &&
          flashcardMaterial.length < LEN_OF_RESULT &&
          radomInt % 3 === 0
        ) {
          countOfWords++;
          flashcardMaterial.push({ type: 'word', content: word });
        }
      });
    }
  }

  if (flashcardMaterial.length < LEN_OF_RESULT) {
    if (otherSentences) {
      otherSentences.forEach((sentence) => {
        if (flashcardMaterial.length < LEN_OF_RESULT) {
          flashcardMaterial.push({ type: 'sentence', content: sentence });
        }
      });
    }
  }

  return flashcardMaterial;
}

export default {
  parseResult4FlashcardMaterials,
};
