/* eslint-disable no-unused-vars */

import PropTypes from 'prop-types';
import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { FlashcardArray } from 'react-quizlet-flashcard';
import { Link } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import 'react-circular-progressbar/dist/styles.css';
import Form from 'react-bootstrap/Form';
import styles from './Clips.module.css';

import { motion, AnimatePresence } from 'framer-motion';

/**
 *
 * @param {string} correctAnswer
 * @param {string} userInput
 * @returns {Array} - [{ isCorrect: false, char, isOvered: true }, ....]
 */
function compareStrings(correctAnswer, userInput) {
  checkStringType(correctAnswer, 'correctAnswer');
  checkStringType(userInput, 'userInput');
  const result = [];

  for (let i = 0; i < userInput.length; i++) {
    const char = userInput[i];
    const isCorrect = correctAnswer[i] == char;
    result.push({ isCorrect, char, isOvered: i >= correctAnswer.length });
  }

  if (userInput && userInput.length < correctAnswer.length) {
    for (let i = userInput.length; i < correctAnswer.length; i++) {
      const char = correctAnswer[i];
      result.push({ isCorrect: false, char });
    }
  }

  return result;
}

/**
 *
 * @param {Array} diff - structure is [{isCorrect: true, char: 'a', isOvered: false}, {isCorrect: false, char: 'b'}]
 * @returns {HTMLElement} <p>
 */
function createPTagForDiff(diff) {
  checkArrType(diff, 'diff');
  return (
    <p className={styles.none_margin}>
      {diff.map((item, index) => {
        const { isCorrect, char, isOvered } = item;
        let color = isCorrect ? 'green' : 'red';
        if (isOvered) color = 'gray';
        return (
          <span key={'subblank_correction_char_' + index} style={{ color }}>
            {char}
          </span>
        );
      })}
    </p>
  );
}

let correctionHider = null;

// ---------------------------------------------------------------
// In this UI component, I will show a input box to let user fill in the blank.
// And I will correct the text in case of wrong type that user writes.
import { useTranslation } from 'react-i18next';
import {
  checkArrType,
  checkStringType,
} from '../../../common/utils/FrontendTypeCheckers';
const SubBlank = (props) => {
  const { t } = useTranslation();
  /**
   * @param {string} input
   * @returns
   */
  function createPTag2ShowCorrection(correction) {
    checkStringType(correction, 'correction');
    return (
      <p className={styles.none_margin} style={{ color: 'red' }}>
        {t('正确答案是：') + correction}
      </p>
    );
  }

  const inputRef = useRef(null);

  const [enableNext, setNextEnable] = useState(true);
  const [inputTxt, setInputTxt] = useState('');
  const [showCorrection, setShowCorrection] = useState(false);
  const [clickedUnfamiliar, setClickedUnfamiliar] = useState(false);

  const { correction, clickInterceptor } = props;

  const submit = () => {
    if (clickInterceptor(inputTxt == correction)) return;
    if (!inputTxt) {
      setClickedUnfamiliar(true);

      correctionHider = setTimeout(() => {
        setClickedUnfamiliar(false);
      }, correction.length * 1000);
    } else if (inputTxt != correction) {
      setNextEnable(false);
      setShowCorrection(true);
    }
  };
  const handleKeyPress = (target) => {
    if (target.charCode == 13) {
      submit();
    }
  };

  const unfamiliar = () => {
    if (clickInterceptor(false)) return;
    setClickedUnfamiliar(true);

    correctionHider = setTimeout(() => {
      setClickedUnfamiliar(false);
    }, correction.length * 1000);
  };

  const onKeyDown = (event) => {
    const { key, altKey, ctrlKey, shiftKey } = event;
    if (altKey == true || shiftKey == true) {
      return;
    }
    if (ctrlKey && key == 'u') {
      event.preventDefault();
      unfamiliar();
    }
  };
  useEffect(() => {
    inputRef.current?.focus();
    window.addEventListener('keydown', onKeyDown);
    return () => {
      clearTimeout(correctionHider);
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return (
    <Col>
      <Row>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          {/* in case it wrong, this label will show some thing.*/}

          <AnimatePresence key="complex_learning_pager_answer_sub_blank">
            <motion.div
              style={{ height: '1.5em' }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
            >
              {showCorrection
                ? createPTagForDiff(compareStrings(correction, inputTxt))
                : null}

              {!showCorrection && clickedUnfamiliar
                ? createPTag2ShowCorrection(correction)
                : null}

              {!showCorrection && !clickedUnfamiliar ? <p> </p> : null}
            </motion.div>
          </AnimatePresence>

          <Form.Control
            type="text"
            onKeyPress={handleKeyPress}
            placeholder={t('请输入该单词')}
            ref={inputRef}
            onChange={(e) => {
              setShowCorrection(false);
              setInputTxt(e.target.value.trim());
              setNextEnable(true);
              if (showCorrection) {
                setClickedUnfamiliar(false);
                clearTimeout(correctionHider);
              }
            }}
          />
        </Form.Group>
      </Row>
      <Row className="align-items-end">
        <div className="d-flex justify-content-end">
          <Button disabled={!enableNext} variant="warning" onClick={unfamiliar}>
            {t('不知道')}
          </Button>
          <div className="m-2" />
          <Button
            disabled={!enableNext}
            onClick={() => {
              submit();
            }}
          >
            {t('下一步')}
          </Button>
        </div>
      </Row>
    </Col>
  );
};

SubBlank.propTypes = {
  correction: PropTypes.string.isRequired,
  clickInterceptor: PropTypes.func,
};

export default SubBlank;
