/**
 * Authing接入文档：
 * https://docs.authing.cn/v2/reference/guard/v2/react.html
 *
 * case1:  a response : {"error":"access_denied","error_description":"Unauthorized"} from "https://dev-wwlt7rh1fglv254g.jp.auth0.com/oauth/token"
 *
 *  solution: set Authentication Methods in auth0 dashboad to "None".
 */

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
// React 16/17
// import ReactDOM from "react-dom";
// import { Guard } from '@authing/react-ui-components';

import { useAuth0 } from '@auth0/auth0-react';
// import { Auth0Provider } from '@auth0/auth0-react';
// 引入 css 文件
import '@authing/react-ui-components/lib/index.min.css';

// React 18
// import { createRoot } from 'react-dom/client'
// import { Guard } from "@authing/react18-ui-components";
// import "@authing/react18-ui-components/lib/index.min.css";

function Login() {
  // const [message, setMessage] = useState('');

  // componentdid update

  // 替换你的 AppId
  // const appId = '64bfbff415215d44bc7241a2';

  // const onLogin = (userInfo) => {
  //   console.log(userInfo);
  // };

  useEffect(() => {
    console.log('Login pager loaded.');
  }, []);

  const { loginWithRedirect } = useAuth0();
  return <button onClick={() => loginWithRedirect()}>Log In</button>;

  // return <p className={classes.message}>{message}</p>;
}

export default Login;
