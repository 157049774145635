/* eslint-disable no-unused-vars */

import React from 'react';
import { useEffect, useState, useRef } from 'react';

import LineProgressBar from '../../../common/ui/progressbar/LineProgressBar';

import {
  getWordRecordCacheManager,
  getPhraseCacheManager,
} from '../utils/ManagerGetter';

import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { post, showingResponseError } from '../../../common/apis/requestHelper';
import ExamResultUI from '../Clips/ExamResultUI';
import BACKEND_URLS from '../../../common/URLPaths';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { readUser } from '../../../common/apis/UserInfoHelper';
import {
  parseResult4ListeningMaterials,
  TYPE_WORD_WITH_AUDIO,
  TYPE_PHRASE_WITH_AUDIO,
} from './ListeningMaterialHandler';

// import animation lib.
import { motion, AnimatePresence } from 'framer-motion';
import RoutePaths from '../../../RoutePaths';
import { Col, Row, Container, Button } from 'react-bootstrap';

import SubBlank from '../Clips/SubBlank';
import SubSelection from '../Clips/SubSelection';
import { fetchTextAudio, speech } from '../../../common/utils/TTSUtils';
import {
  checkBooleanType,
  checkObjectType,
  checkArrType,
} from '../../../common/utils/FrontendTypeCheckers';
import { submitSentences } from '../StudySet/StudySetDetailAPI';
// import LearningOptionsModal from './LearningOptionsModal';

/**
 * In this class, the plan is that this pager will contain:
 * 1. selection;
 * 2. fill-in-blank;
 * 3. audio questions;
 * 4. similar words.
 * and there is also a ResultUI.jsx to show the result of the learning.
 **/

//record the result of each material.
const indexScoreMap = new Map();

const phraseScore = new Map();
const uploadResult = (setId) => {
  // 该函数暂时无用， 因为服务端记录的 result，是跟sentence绑定的，听力里不会出现sentence。
};

import { useTranslation } from 'react-i18next';
import Nodata from '../Clips/NoData';
// --------- ComplexLearningUI ---------
function ComplexListeningUI() {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [materials, setMaterials] = useState(undefined);

  const [finished, setFinished] = useState(false);
  const [indexOfMaterial, setIndexOfMaterial] = useState(0);

  const [showingSetting, setShowingSetting] = useState(false);

  const setId = params.setId;

  const maxHeight = window.innerHeight - 120;

  const correctCount = () => {
    let count = 0;
    indexScoreMap.forEach((value, key) => {
      if (value) {
        count++;
      }
    });
    return count;
  };

  if (!setId) {
    console.error('setId is not provided.');
    window.showErrorModal(t('数据异常'));
    return <div>setId is not provided.</div>;
  }

  const onClickedPrev = () => {
    if (indexOfMaterial > 0) {
      setIndexOfMaterial(indexOfMaterial - 1);
    }
  };

  const clickInterceptor = (boolValue) => {
    checkBooleanType(boolValue);
    let hasToSpeech = false;
    if (!indexScoreMap.has(indexOfMaterial)) {
      // avoid repeated putting.
      indexScoreMap.set(indexOfMaterial, boolValue);
      if (
        [TYPE_WORD_WITH_AUDIO, TYPE_PHRASE_WITH_AUDIO].includes(
          materials[indexOfMaterial].type
        )
      ) {
        const content = materials[indexOfMaterial].content;

        phraseScore.set(content.id, boolValue);
        // record the result of content.
        const recorder =
          TYPE_PHRASE_WITH_AUDIO == materials[indexOfMaterial].type
            ? getPhraseCacheManager()
            : getWordRecordCacheManager();
        boolValue
          ? recorder.recordSuc(content.id)
          : recorder.recordFailure(content.id);
      }
    }
    if (boolValue) {
      if (indexOfMaterial == materials.length - 1) {
        setFinished(true);
        uploadResult(setId);
        return true;
      } else {
        const ttsText = materials[indexOfMaterial + 1].content?.ttsText;
        if (ttsText) {
          fetchTextAudio(materials[indexOfMaterial + 1].content?.ttsText);
        }
        setTimeout(
          () => {
            setIndexOfMaterial(indexOfMaterial + 1);
          },
          hasToSpeech ? 1500 : 500
        );
      }
    }
    return boolValue;
  };

  // only return Audio clip.
  function createContentClip(material) {
    if (!material) throw new Error('material is not provided.');
    const ERR_PLACEHOLDER = '**error**';
    if (!material.type) throw new Error('material.type is not provided.');

    const { type, content } = material;

    let ttsText = content.ttsText || ERR_PLACEHOLDER;
    let phrase;
    if ([TYPE_PHRASE_WITH_AUDIO].includes(type)) {
      phrase = content;
      console.log('phrase: ', JSON.stringify(phrase));
    }
    return (
      <Container className="d-flex align-items-center justify-content-center">
        <div>
          <Col className="justify-content-center align-items-center">
            <div className="d-flex justify-content-center align-items-center">
              <Icon.VolumeUp
                onClick={(e) => {
                  e.stopPropagation();
                  if (ERR_PLACEHOLDER != ttsText) {
                    speech(ttsText);
                  }
                }}
                style={{ fontSize: '5em' }}
                color="#7532f9"
              />
            </div>
            <div className="spacer" />
            {window.language == 'zh' && phrase && phrase.cnMeaning ? (
              <p className="justify-content-center">{phrase.cnMeaning}</p>
            ) : null}
          </Col>
        </div>
      </Container>
    );
  }

  // only return <SubBlank>
  function createAnswerCpnt(material) {
    if (!material) throw new Error('material is not provided.');
    if (!material.type) throw new Error('material.type is not provided.');

    const { type, content } = material;

    const ERROR_PLACEHOLDER = '**error**';

    // 如果是 word's phrase, content.word 是非空，但是如果是单纯的phrase，content.word 是空的。
    return (
      <SubBlank
        correction={content.word || content.text || ERROR_PLACEHOLDER}
        clickInterceptor={clickInterceptor}
      />
    );
  }

  const warpPresenceAnimation = (comp) => {
    return (
      <AnimatePresence key="wrap_presence_animation_in_complex_learning_pager">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.3 }}
        >
          {comp}
        </motion.div>
      </AnimatePresence>
    );
  };

  const onKeyDown = (event) => {
    console.log(
      'learning event: ' +
        event.key +
        '  ' +
        event.altKey +
        '  ' +
        event.ctrlKey +
        '  ' +
        event.shiftKey
    );
    const { key, altKey, ctrlKey, shiftKey } = event;
    if (altKey == true || shiftKey == true) {
      return;
    }
    if (key == 'ArrowUp') {
      event.preventDefault();
      event.stopPropagation();
      onClickedPrev();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    if (setId) {
      post(BACKEND_URLS.LISTEN_MATERIALS, {
        setId: setId,
        needSupplementary: false, //{t('暂时用不到，目前主要是全拼')} {t('填空，有选择题时会用到这个')}
        needCollocations: true,
        voice: 0,
      })
        .then((response) => {
          setMaterials(parseResult4ListeningMaterials(response.data));
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          !showingResponseError &&
            window.showErrorModal('' + error, () => {
              setLoading(false);
            });
        });
    }
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  if (loading) {
    return (
      <div className="d-flex align-items-center  justify-content-center">
        <Spinner animation="grow" />
      </div>
    );
  } else if (finished) {
    const count = correctCount();
    const resultUI = (
      <ExamResultUI
        correctCount={count}
        totalCount={materials ? materials.length : 0}
      />
    );
    return warpPresenceAnimation(resultUI);
  }

  //{t('避免')}bug
  if (!materials || materials.length === 0) {
    return <Nodata />;
  }

  const currentMaterial = materials[indexOfMaterial];
  console.log(currentMaterial);
  const currentClipCpn = createContentClip(currentMaterial);
  const answerCpn = createAnswerCpnt(currentMaterial);

  return (
    <Container
      className="flex-column d-flex"
      key={'complex_learning_pager_container_index_' + indexOfMaterial}
      fluid
      style={{ height: maxHeight, fontSize: '1.2em' }}
    >
      {/* LineProgressBar */}
      <LineProgressBar
        progress={indexOfMaterial}
        total={materials?.length || 100}
      />
      <div className="spacer" />
      {/* top line */}
      <Row className="d-flex flex-row">
        <Col md={2} sm={4}>
          <Button
            onClick={onClickedPrev}
            disabled={indexOfMaterial == 0}
            variant="outline-secondary"
          >
            <span>
              <Icon.ArrowLeft /> {t('上一个')}
            </span>
          </Button>
        </Col>
        <Col md={8} sm={4} className="text-center">
          {indexOfMaterial + 1}/{materials ? materials.length : 0}
        </Col>
        <Col md={2} sm={4}>
          <div className="justify-content-end align-items-end">
            <Button
              onClick={() => {
                setShowingSetting(true);
              }}
              variant="outline-secondary"
            >
              <span>
                <Icon.Sliders /> {t('自定义')}
              </span>
            </Button>
          </div>
        </Col>
      </Row>

      {/* content */}
      <Row className="d-flex flex-grow-1">
        <AnimatePresence key="wrap_presence_animation_in_complex_learning_pager_content">
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            className="d-flex align-items-center justify-content-center"
            transition={{ duration: 0.6 }}
          >
            {currentClipCpn}
          </motion.div>
        </AnimatePresence>
      </Row>
      <Row className="d-flex">
        <AnimatePresence key="wrap_presence_animation_in_complex_learning_pager_answer">
          <motion.div
            initial={{ opacity: 0, x: '100%' }}
            animate={{ opacity: 0.9, x: 0 }}
            exit={{ opacity: 0, x: '-100%' }}
            transition={{ duration: 0.6 }}
          >
            {answerCpn}
          </motion.div>
        </AnimatePresence>
      </Row>
    </Container>
  );
}
export default ComplexListeningUI;
