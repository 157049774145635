// in this file, I will save settings like audio, meanings, shuffle, keyboard into localstorage.

const KEY_LEARNING_SETTING = 'key_learning_settings';
// Firstly, I use a class to map those  settings into a object.
export class StudySetting {
  constructor() {
    this.autoPlayAudio = false;
    this.useKeyboard = false;
    this.onlyStared = false;
    this.shuffle = false;
    this.enableMeanings = true;
    this.relateImages = false;
  }
  autoPlayAudio;
  useKeyboard;
  onlyStared;
  shuffle;
  enableMeanings;
  relateImages;
}

// I will sync this object to json string and then save it into localstorage.
// I will use this object to control the UI.

const setting =
  JSON.parse(localStorage.getItem(KEY_LEARNING_SETTING)) || new StudySetting();

const syncObj = () => {
  localStorage.setItem(KEY_LEARNING_SETTING, JSON.stringify(setting));
};

/**
 * @returns StudySetting
 */
export const getStudySetting = () => {
  return setting;
};

export const saveStudySetting = () => {
  syncObj();
};

export default {
  StudySetting,
  getStudySetting,
  saveStudySetting,
};
