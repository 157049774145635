/* eslint-disable no-unused-vars */
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { Button, Modal, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { Dropdown, Row, Col } from 'react-bootstrap';

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { readUser } from '../../../common/apis/UserInfoHelper';

import BACKEND_URLS from '../../../common/URLPaths';
import {
  post,
  showingResponseError,
  postWithTimeout,
} from '../../../common/apis/requestHelper';
import { useTranslation } from 'react-i18next';
import WordLibUI from './WordLibUI';
// styles import
import styles from './SideDrawer.module.css';
import RoutePaths from '../../../RoutePaths';
import CustomHelmet from '../../../common/ui/CustomHelmet';

// key to title map.
const TAB_NAME_MAP = {
  CN: '国内考试',
  INT: '国际考试',
};

const MainTabs = {
  PhrasalTAB: 'phrasal_tab',
  WordTAB: 'vocab_tab',
};

const MainLibSelector = () => {
  const { t } = useTranslation();
  const history = useHistory();

  // get window.height
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  // current tab
  const [currentMainTab, setCurrentMainTab] = useState(MainTabs.WordTAB); // [MainTabs.WordTAB,setCurrentMainTab]

  // its so significant to determine need or no to call api.
  const [isLoading, setIsLoading] = useState(true);

  const [wordlibMenus, setWordlibMenus] = useState([]);
  const [wordlibs, setWordlibs] = useState([]);
  const [phrasalLibMenus, setPhrasalLibMenus] = useState([]);
  const [phrasalLibs, setPhrasalLibs] = useState([]);

  const [wordlibTabIndex, setWordlibTabIndex] = useState(0);
  const [phrasalLibTabIndex, setPhrasalLibTabIndex] = useState(0);

  // states below for popup drawer ------------
  const [showingStudySetSplits, setShowingStudySetSplits] = useState(false);
  // item in this array should be like [string,]
  const [importingStudySetNames, setImportingStudySetNames] = useState([]);
  const [selBookTitle, setSelBookTitle] = useState('');
  const [chooseLib, setChooseLib] = useState(null);
  // use preference
  const [userPreferredSize, setUserPreferredSize] = useState(100);
  // ------------ states below for popup drawer

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = () => {
    if (!chooseLib) {
      !showingResponseError && window.showErrorModal(t('请选择词库'));
      return;
    }
    const user = readUser();
    if (!user) {
      !showingResponseError && window.showErrorModal(t('请先登录'));
      return;
    }
    setIsSubmitting(true);
    const importPath =
      currentMainTab == MainTabs.WordTAB
        ? BACKEND_URLS.IMPORT_WORDS_VIA_LIB
        : BACKEND_URLS.IMPORT_PHRASES_VIA_LIB;
    postWithTimeout(
      importPath,
      {
        size: userPreferredSize,
        libId: chooseLib.id,
        lan: window.language,
      },
      15 * 1000
    )
      .then((res) => {
        console.log('res', JSON.stringify(res));
        const code = res.data?.code;
        console.warn('code', code);
        if (code == 0) {
          const { importedLen } = res.data;
          setShowingStudySetSplits(false);
          window.showSucModal(t('导入成功'), () => {
            history.push(RoutePaths.mergeMine(RoutePaths.SUB_HOMEWORK));
          });
        } else {
          let msg = t('导入失败');
          msg = code == 2 ? t('请勿重复导入该词库') : msg;
          msg = code == 3 ? t('请勿重复导入该词库，请检查词表或回收站。') : msg;
          window.showErrorModal(msg);
        }
      })
      .catch((err) => {
        console.log('err', err);
        let msg = t('导入失败');
        if (err.message.includes('timeout')) {
          msg = t('网络超时，可能已经导入成功。');
        }
        !showingResponseError && window.showErrorModal(msg);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  /**
   *
   * @param {string || undefined} name
   * @param {number || undefined} limit - to limit size of studyset
   */
  const recalculateStudySetNames = (name, limit) => {
    const length = chooseLib.size;
    console.log('recalculateStudySetNames#', name, limit);
    const prefix = (name || chooseLib.name) + '-';
    const studySetCount = Math.ceil(length / (limit || userPreferredSize));

    const studySetNames = [];
    for (let i = 0; i < studySetCount; i++) {
      const newName = `${prefix}${i + 1}`;
      studySetNames.push(newName);
      // check name
      if (!newName.includes('-'))
        throw new Error('study name should include -');
    }

    setImportingStudySetNames(studySetNames);
  };

  useEffect(() => {
    if (chooseLib) {
      recalculateStudySetNames();
    }
    if (isLoading) {
      // add condition to avoid repeatedly calling api.
      setTimeout(() => {
        (!wordlibs || wordlibs.length == 0) &&
          post(BACKEND_URLS.WORDLIBS, { lan: window.language })
            .then((res) => {
              console.log('MainLibSelector res:', res);
              const { data } = res;
              setWordlibs(data);
              setWordlibMenus(
                data.map((item) => {
                  return { title: item.title };
                })
              );
            })
            .catch((err) => {
              console.log('err', err);
            })
            .finally(() => {
              setIsLoading(false);
            });

        (phrasalLibs || phrasalLibs.length == 0) &&
          post(BACKEND_URLS.PHRASAL_LIBS, { lan: window.language })
            .then((res) => {
              console.log('MainLibSelector setPhrasalLibs:', res.data);
              const { data } = res;
              setPhrasalLibs(data);
              setPhrasalLibMenus(
                data.map((item) => {
                  return { title: item.title };
                })
              );
            })
            .catch((err) => {
              console.log('err', err);
            })
            .finally(() => {
              setIsLoading(false);
            });
      }, 300);
    }
  }, [userPreferredSize, chooseLib]);

  const handleDrawerClose = () => {
    setShowingStudySetSplits(false);
    setImportingStudySetNames([]);
    setUserPreferredSize(100);
  };

  // dropdown onSelect# function. the size is limit of words in a studyset.
  const onSizeChoose = (eventKey) => {
    console.log('onSizeChoose', eventKey);
    const limit = parseInt(eventKey.replace('#/', ''));
    setUserPreferredSize(limit);
    recalculateStudySetNames(selBookTitle, limit);
  };

  const selectedWordlib = (lib) => {
    const { name } = lib;
    console.log('selectedWordlib :', lib);
    setShowingStudySetSplits(true);
    setSelBookTitle(name);
    // ---------- !!! stop using callback in setState. use useEffect instead.---------
    // setChooseLib(lib, ()=>{recalculateStudySetNames()});
    setChooseLib(lib);
  };

  const handleWordToggleButtonChange = (e) => {
    setWordlibTabIndex(e.target.value);
  };

  const handlePhrasalToggleButtonChange = (e) => {
    setPhrasalLibTabIndex(e.target.value);
  };

  // loading...
  if (isLoading) {
    return (
      <div>
        <CustomHelmet currentMeta={window.metas.import_via_libs} />
        <div className="d-flex  align-items-center  justify-content-center"></div>
        <div className="d-flex  align-items-center  justify-content-center">
          <span>
            <Spinner animation="grow" size="sm" /> loading...
          </span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <CustomHelmet currentMeta={window.metas.import_via_libs} />
      <h2>{t('从词库导入')}</h2>

      <Tabs
        fill
        defaultActiveKey={MainTabs.WordTAB}
        onSelect={(e) => {
          setCurrentMainTab(e);
        }}
      >
        <Tab eventKey={MainTabs.WordTAB} title={t('单词库')}>
          <div className="p-2">
            <div className="p-4">
              <ButtonGroup
                className="d-flex"
                value={wordlibTabIndex}
                onChange={handleWordToggleButtonChange}
              >
                {wordlibMenus.map((item, index) => {
                  return (
                    <ToggleButton
                      id={'wordlibmenu-tgbtn-' + index}
                      key={'wordlibmenu-tgbtn-' + index}
                      type="radio"
                      variant={
                        wordlibTabIndex == index
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      name="radio"
                      className={'btn-block mr-1 mt-1 btn-lg'}
                      size="lg"
                      value={index}
                    >
                      {window.language
                        ? TAB_NAME_MAP[item.title] || item.title
                        : item.title}
                    </ToggleButton>
                  );
                })}
              </ButtonGroup>
            </div>
            {wordlibs[wordlibTabIndex] ? (
              <WordLibUI
                keyPrefix={'wordtab_libs_'}
                category={wordlibs[wordlibTabIndex].title}
                wordlibs={wordlibs[wordlibTabIndex].categories}
                onChooseLib={selectedWordlib}
              />
            ) : null}
          </div>
        </Tab>
        <Tab eventKey={MainTabs.PhrasalTAB} title={t('词组库')}>
          {phrasalLibs.length == 0 ? (
            <p className="fs-6">{t('敬请期待')}</p>
          ) : (
            <div className="p-2">
              <div className="p-4">
                <ButtonGroup
                  className="d-flex"
                  value={phrasalLibTabIndex}
                  onChange={handlePhrasalToggleButtonChange}
                >
                  {phrasalLibMenus.map((item, index) => {
                    return (
                      <ToggleButton
                        id={'phrasalLibmenu-tgbtn-' + index}
                        key={'phrasalLibmenu-tgbtn-' + index}
                        type="radio"
                        variant={
                          phrasalLibTabIndex == index
                            ? 'primary'
                            : 'outline-secondary'
                        }
                        name="radio"
                        className={'btn-block mr-1 mt-1 btn-lg'}
                        size="lg"
                        value={index}
                      >
                        {window.language
                          ? TAB_NAME_MAP[item.title] || item.title
                          : item.title}
                      </ToggleButton>
                    );
                  })}
                </ButtonGroup>
              </div>

              {phrasalLibs[phrasalLibTabIndex] ? (
                <WordLibUI
                  keyPrefix="phrasal_lib_tab_"
                  category={phrasalLibs[phrasalLibTabIndex].title}
                  wordlibs={phrasalLibs[phrasalLibTabIndex].categories}
                  onChooseLib={selectedWordlib}
                />
              ) : null}
            </div>
          )}
        </Tab>
      </Tabs>

      <Modal
        show={showingStudySetSplits}
        onHide={handleDrawerClose}
        dialogClassName={styles.drawer_modal}
        contentClassName={styles.drawer_modal_content}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('导入') + '《' + selBookTitle + '》'}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ height: windowHeight - 135 + 'px' }}
          className="overflow-auto"
        >
          <Col className="px-2">
            {/* show size selection*/}
            <Row>
              <Col>
                <p className="fs-6">{t('学习集大小：')}</p>
              </Col>
              <Col>
                <Dropdown
                  onSelect={onSizeChoose}
                  className="d-flex justify-content-end"
                >
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {'  ' + userPreferredSize + '  '}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/30">30</Dropdown.Item>
                    <Dropdown.Item href="#/50">50</Dropdown.Item>
                    <Dropdown.Item href="#/100">100</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>

            <Row className="ms-1 text-secondary">{t('即将创建学习集：')}</Row>

            <Row>
              {importingStudySetNames.map((item, index) => {
                return (
                  <Col key={index} className="mt-3">
                    <div
                      className="p-2 pe-3 bg-white bg-opacity-10 border border-light rounded  border-opacity-50"
                      style={{ width: '120px' }}
                    >
                      {item}
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="px-3"
            variant="secondary"
            onClick={handleDrawerClose}
          >
            {t('关闭')}
          </Button>
          <Button
            className="ms-3 px-3"
            variant="primary"
            disabled={isSubmitting}
            onClick={onSubmit}
          >
            {isSubmitting ? (
              <div>
                {t('导入') + '...'} <Spinner animation="border" size="sm" />
              </div>
            ) : (
              t('导入')
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MainLibSelector;
