// in this manger, I use localStorage, but it may be cleared by browser.
import { checkStringType } from '../../../common/utils/FrontendTypeCheckers';
import LimitedMap from '../../../common/utils/LimitedMap';

export class LearningRecordManager {
  _KEY_CACHE_FAILURE_SENTENCE_MAP;
  _KEY_CACHE_SUC_SENTENCE_MAP;
  _cacheFailureMap;
  _cacheSucMap;

  syncTask = undefined;

  constructor(prefix) {
    checkStringType(prefix);
    this._KEY_CACHE_FAILURE_SENTENCE_MAP = this.prefix + '__cacheFailureMap';
    this._KEY_CACHE_SUC_SENTENCE_MAP = this.prefix + '__cacheSucMap';
    this._cacheFailureMap = undefined;
    this._cacheSucMap = undefined;
    this.initCacheMap();
  }

  syncWithDelay() {
    if (this.syncTask) clearTimeout(this.syncTask);
    if (!this._cacheFailureMap)
      throw new Error('_cacheFailureMap is undefined');
    if (!this._cacheSucMap) throw new Error('_cacheSucMap is undefined');

    this.syncTask = setTimeout(() => {
      localStorage.setItem(
        this._KEY_CACHE_FAILURE_SENTENCE_MAP,
        JSON.stringify(this._cacheFailureMap)
      );
      localStorage.setItem(
        this._KEY_CACHE_SUC_SENTENCE_MAP,
        JSON.stringify(this._cacheSucMap)
      );
      this.syncTask = undefined;
    }, 1800);
  }

  initCacheMap() {
    if (this._cacheFailureMap === undefined) {
      this._cacheFailureMap = new LimitedMap(200);
      const _cacheFailureMapStr =
        localStorage.getItem(this._KEY_CACHE_FAILURE_SENTENCE_MAP) || '{}';
      const _cacheFailureMapObj = JSON.parse(_cacheFailureMapStr);
      for (const [key, value] of Object.entries(_cacheFailureMapObj)) {
        this._cacheFailureMap.set(key, value);
      }
    }
    if (this._cacheSucMap === undefined) {
      this._cacheSucMap = new LimitedMap(200);
      const _cacheSucMapStr =
        localStorage.getItem(this._KEY_CACHE_SUC_SENTENCE_MAP) || '{}';
      const _cacheSucMapObj = JSON.parse(_cacheSucMapStr);
      for (const [key, value] of Object.entries(_cacheSucMapObj)) {
        this._cacheSucMap.set(key, value);
      }
    }
  }

  recordFailure(id) {
    this.initCacheMap();
    if (this._cacheFailureMap.has(id)) {
      const record = this._cacheFailureMap.get(id);
      record.failureCount += 1;
      this._cacheFailureMap.set(id, record);
    } else {
      this._cacheFailureMap.set(id, {
        failureCount: 1,
      });
    }
    this.syncWithDelay();
  }

  recordSuc(id) {
    this.initCacheMap();
    if (this._cacheFailureMap.has(id)) {
      const record = this._cacheFailureMap.get(id);
      record.failureCount -= 2;
      if (record.failureCount < 1) {
        this._cacheFailureMap.delete(id);
      } else {
        this._cacheFailureMap.set(id, record);
      }
    }

    const record = this._cacheSucMap.has(id)
      ? this._cacheSucMap.get(id)
      : { sucCount: 0 };
    record.sucCount += 1;
    this._cacheSucMap.set(id, record);

    this.syncWithDelay();
  }

  clearCache() {
    this._cacheFailureMap && this._cacheFailureMap.clear();
    this._cacheSucMap && this._cacheSucMap.clear();

    localStorage.removeItem(this._KEY_CACHE_FAILURE_SENTENCE_MAP, '{}');
    localStorage.removeItem(this._KEY_CACHE_SUC_SENTENCE_MAP, '{}');
  }

  getSucCount(id) {
    this.initCacheMap();
    return this._cacheSucMap.has(id) ? this._cacheSucMap.get(id).sucCount : 0;
  }

  getFailureCount(id) {
    this.initCacheMap();
    return this._cacheFailureMap.has(id)
      ? this._cacheFailureMap.get(id).failureCount
      : 0;
  }
}

export default LearningRecordManager;
