/* eslint-disable no-unused-vars */
import React from 'react';
import { useState, useEffect, useRef } from 'react';

import { Button, Col, Row } from 'react-bootstrap';

import { Grid } from 'react-virtualized';
import { List, AutoSizer } from 'react-virtualized';

import { useTranslation } from 'react-i18next';
import BACKEND_URLS from '../../common/URLPaths';

import styles from './DeletedStudySets.module.css';
// card
import Card from 'react-bootstrap/Card';
import { post, showingResponseError } from '../../common/apis/requestHelper';

import PropTypes from 'prop-types';

import { isPagerComplete } from '../../common/utils/WebPagerHelper';
let GRID_COLUMN_COUNT = 3;

const TrashItems = (props) => {
  const { t } = useTranslation();

  const listRef = useRef(null);
  const { datalist, type } = props;

  console.log(`type is ${type}, datalist.size is ${datalist.length}.`);

  const studysetArr = datalist || [];

  const [countOfLists, setCountOfLists] = useState(type == 1 ? 1 : 0);

  useEffect(() => {
    console.log(`countOfLists is ${countOfLists}.`);
    const parentId = 'trash-grid-container-' + type;
    const w = document.getElementById(parentId).clientWidth;
    GRID_COLUMN_COUNT = Math.floor(w / 314);
    GRID_COLUMN_COUNT = GRID_COLUMN_COUNT > 0 ? GRID_COLUMN_COUNT : 1;
    if (countOfLists != studysetArr.length) {
      setCountOfLists(studysetArr.length);
    }
  }, [datalist, GRID_COLUMN_COUNT]);

  if (studysetArr.length == 0) return null;

  const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    const index = rowIndex * GRID_COLUMN_COUNT + columnIndex;
    if (index >= countOfLists) return null;

    const { countOfGenerated, countOfImported } = studysetArr[index];
    const diff = countOfImported - countOfGenerated;
    const isAIGenerating = diff > 0;

    return (
      <div key={key + '_child_div_' + index}>
        <Card
          className={styles.studyset_card}
          aria-disabled={isAIGenerating}
          key={key + 'card_' + index}
          style={{
            ...style,
            margin: '10px',
            width: '300px',
            height: '180px',
          }}
        >
          <Card.Body>
            <div style={{ padding: '1em' }}>
              <Col>
                <Row>
                  <h4>{studysetArr[index].listName}</h4>
                </Row>
                <Col className="float-end">
                  <div>
                    {type == 1 ? (
                      <p>
                        {t('单词数：')}
                        {studysetArr[index].countOfImported}
                      </p>
                    ) : (
                      <p>
                        {t('词组数：')}
                        {studysetArr[index].countOfImported}
                      </p>
                    )}
                  </div>
                  <div>
                    <Button onClick={() => retrieveSet(studysetArr[index].id)}>
                      {' '}
                      {t('还原')}
                    </Button>
                  </div>
                </Col>
              </Col>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  };

  const retrieveSet = (setId) => {
    // delete item from studysetArr
    const index = studysetArr.findIndex((item) => item.id === setId);
    studysetArr.splice(index, 1);
    setCountOfLists(studysetArr.length);

    post(BACKEND_URLS.STUDYSET_DELETE, {
      setId,
      retrieve: true,
    })
      .then((rsp) => {
        if (rsp.code == 0) {
          window.showSuccessModal(t('操作成功'));
        } else {
          !showingResponseError &&
            window.showErrorModal(rsp.message || t('操作失败'), () =>
              window.location.reload()
            );
        }
      })
      .catch((err) => {
        console.log(err);
        !showingResponseError && window.showErrorModal(t('系统错误'));
      });
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Grid
          ref={listRef}
          key={'trash-grid'}
          className="justify-content-between"
          cellRenderer={cellRenderer}
          columnCount={GRID_COLUMN_COUNT}
          rowCount={Math.floor(countOfLists / GRID_COLUMN_COUNT) + 1}
          columnWidth={320}
          rowHeight={190}
          width={width}
          height={height}
          style={{}}
        />
      )}
    </AutoSizer>
  );
};

TrashItems.propTypes = {
  datalist: PropTypes.array.isRequired,
  type: PropTypes.number.isRequired,
};
export default TrashItems;
