// in this file, I will save settings like audio, meanings, shuffle, keyboard into localstorage.
import {
  checkBooleanType,
  checkStringType,
} from '../../../common/utils/FrontendTypeCheckers';
import FullscreenFlashcardSettings from './FullscreenFlashcardSettings';
const KEY_FLASHCARD_SETTING = 'key_fullscreen_flashcard_settings';

// I will sync this object to json string and then save it into localstorage.
// I will use this object to control the UI.

const setting =
  JSON.parse(localStorage.getItem(KEY_FLASHCARD_SETTING)) ||
  new FullscreenFlashcardSettings();

const syncObj = () => {
  localStorage.setItem(KEY_FLASHCARD_SETTING, JSON.stringify(setting));
};

/**
 * @returns FlashcardSetting
 */
export const getFlashcardSetting = () => {
  return setting;
};

export const getFeatureEnable = (featureKey) => {
  checkStringType(featureKey);
  return setting[featureKey];
};

export const setFeatureEnable = (featureKey, value) => {
  checkStringType(featureKey);
  checkBooleanType(featureKey, value);
  setting[featureKey] = value;
  syncObj();
};

export const saveFlashcardSetting = () => {
  syncObj();
};

// optional features
/**
 *     this.autoPlayAudio = true;
    this.useKeyboard = true;
    this.onlyStared = false;
    this.shuffle = false;
    this.enableMeanings = true;
    this.relateImages = false;
 */
export const OPT_FEATURES = {
  KEYBOARD: 'useKeyboard',
  MEANING: 'enableMeanings',
  IMAGE: 'relateImages',
  SHUFFLE: 'shuffle',
  AUTOPLAY: 'autoPlayAudio',
  STARRED: 'onlyStared',
};

export default {
  getFlashcardSetting,
  saveFlashcardSetting,
  OPT_FEATURES,
};
