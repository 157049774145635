/* eslint-disable no-unused-vars */

import React from 'react';
import { useEffect, useState, useRef } from 'react';

import LineProgressBar from '../../../common/ui/progressbar/LineProgressBar';

import { getStudySetting } from './LearningOptionsStorage';

import { getSentenceCacheManager } from '../utils/ManagerGetter';

import { NavLink, Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { post, showingResponseError } from '../../../common/apis/requestHelper';
import ExamResultUI from '../Clips/ExamResultUI';
import BACKEND_URLS from '../../../common/URLPaths';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { readUser } from '../../../common/apis/UserInfoHelper';
import {
  parseResult4LearningMaterials,
  TYPE_AUDIO,
  TYPE_SELECTION,
  TYPE_BLANK,
} from './LearningMaterialHandler';
// import animation lib.
import { motion, AnimatePresence } from 'framer-motion';
import RoutePaths from '../../../RoutePaths';
import { Col, Row, Container, Button } from 'react-bootstrap';

import SubBlank from '../Clips/SubBlank';
import SubSelection from '../Clips/SubSelection';
import { fetchTextAudio, speech } from '../../../common/utils/TTSUtils';
import {
  checkBooleanType,
  checkObjectType,
} from '../../../common/utils/FrontendTypeCheckers';
import { submitSentences } from '../StudySet/StudySetDetailAPI';
import LearningOptionsModal from './LearningOptionsModal';

import { getNonEnglishLanguage } from '../../../common/utils/LanguageHelper';

/**
 * In this class, the plan is that this pager will contain:
 * 1. selection;
 * 2. fill-in-blank;
 * 3. audio questions;
 * 4. similar words.
 * and there is also a ResultUI.jsx to show the result of the learning.
 **/

/**
 * Options for the exercise:
 * 1. auto-play audio;
 * 2. show CN/EN meanings;
 *
 */
const indexScoreMap = new Map();

const sentenceScores = new Map();
const uploadResult = (setId) => {
  if (!setId) throw new Error('setId is not provided.');
  if (sentenceScores.length < 1) return;
  const sentences = []; // item in it be like: [id: 1, isRemember: true]
  sentenceScores.forEach((value, key) => {
    sentences.push({ id: key, isRemember: value });
  });
  if (sentences.length < 1) throw new Error('sentence is empty');
  submitSentences(sentences, setId)
    .then((result) => {
      if (!result) {
        console.warn('sync score failed');
      }
    })
    .catch((error) => {
      console.error('sync score failed');
    });
};
import { useTranslation } from 'react-i18next';
import Nodata from '../Clips/NoData';
import Hinter from '../../../common/ui/Hinter';

// input: {content: 'test'} or { content: {Word: {word: 'test'}}}.
function getTTSText(material) {
  if (!material) throw new Error('material is not provided.');
  if (typeof material.content == 'string') {
    return material.content;
  }

  const ttsSource =
    material.content?.Word?.word || material.content?.Phrase?.text;

  if (!ttsSource) {
    console.log('material : ' + JSON.stringify(material));
  }

  return ttsSource;
}

// --------- ComplexLearningUI ---------
function ComplexLearningUI() {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [materials, setMaterials] = useState(undefined);

  const [finished, setFinished] = useState(false);
  const [indexOfMaterial, setIndexOfMaterial] = useState(0);

  const [showingSetting, setShowingSetting] = useState(false);

  const [hinterData, setHinterData] = useState(undefined);

  const setId = params.setId;

  // translation and indexOfShowingTranslation are used to showing translation below sentence.
  const [indexOfShowingTranslation, setIndexOfShowingTranslation] =
    useState(-1);
  const [translation, setTranslation] = useState(null);
  const translate = (text) => {
    const bodyJSON = { text: text, toLan: getNonEnglishLanguage() };
    post(BACKEND_URLS.TRANSLATION, bodyJSON)
      .then((response) => {
        const data = response.data;
        setTranslation(data.translation);
      })
      .catch((error) => {
        window.showErrorModal('' + error);
      });
  };

  const maxHeight = window.innerHeight - 120;

  const resetHinterData = (i) => {
    setHinterData(null);
    let index = i || indexOfMaterial;
    if (materials && index >= 0 && index <= materials.length - 1) {
      const material = materials[index];
      _setHinterByMaterial(material);
    }
  };

  // TODO , it does not support for phrases.
  const _setHinterByMaterial = (material) => {
    const sentence = material.content?.Sentence;
    const { word } = material.content?.Word || {};
    sentence && word && setHinterData({ word, sentence });
  };

  const correctCount = () => {
    let count = 0;
    indexScoreMap.forEach((value, key) => {
      if (value) {
        count++;
      }
    });
    return count;
  };

  if (!setId) {
    console.error('setId is not provided.');
    window.showErrorModal(t('数据异常'));
    return <div>setId is not provided.</div>;
  }

  function onClickedPrev() {
    if (indexOfMaterial > 0) {
      console.warn('prev');
      setIndexOfMaterial(indexOfMaterial - 1);
      setHinterData(indexOfMaterial - 1);
    }
  }

  const clickInterceptor = (boolValue) => {
    const { autoPlayAudio } = getStudySetting();
    checkBooleanType(boolValue);
    let hasToSpeech = false;
    if (!indexScoreMap.has(indexOfMaterial)) {
      // avoid repeated putting.
      indexScoreMap.set(indexOfMaterial, boolValue);
      if (
        [TYPE_BLANK, TYPE_SELECTION].includes(materials[indexOfMaterial].type)
      ) {
        const sentence = materials[indexOfMaterial].content;
        if (autoPlayAudio && sentence.Word?.word) {
          speech(sentence.Word?.word);
          hasToSpeech = true;
        }
        checkObjectType(sentence, 'sentence');
        sentenceScores.set(sentence.id, boolValue);
        // record the result of sentence.
        boolValue
          ? getSentenceCacheManager().recordSuc(sentence.id)
          : getSentenceCacheManager().recordFailure(sentence.id);
      }
    }
    if (boolValue) {
      if (indexOfMaterial == materials.length - 1) {
        setFinished(true);
        uploadResult(setId);
        return true;
      } else {
        const nextM = materials[indexOfMaterial + 1];
        if (nextM) {
          const ttsSource = getTTSText(nextM);
          if (ttsSource) {
            if (autoPlayAudio && nextM.type == TYPE_AUDIO) {
              speech(ttsSource);
            } else {
              fetchTextAudio(ttsSource);
            }
          }
        }
        setTimeout(
          () => {
            const next = indexOfMaterial + 1;
            console.warn('next ' + next);
            setIndexOfMaterial(next);
            resetHinterData(next);
          },
          hasToSpeech ? 1500 : 500
        );
      }
    }
    return boolValue;
  };

  function createClip(material) {
    if (!material) throw new Error('material is not provided.');
    const ERR_PLACEHOLDER = '**error**';
    if (!material.type) throw new Error('material.type is not provided.');

    const { type } = material;
    if (type === TYPE_AUDIO) {
      fetchTextAudio(getTTSText(material));
      return (
        <Col className="d-flex align-items-center justify-content-center">
          <Icon.VolumeUp
            onClick={(e) => {
              e.stopPropagation();
              speech(getTTSText(material));
            }}
            style={{ fontSize: '5em' }}
            color="#7532f9"
          />
        </Col>
      );
    } else if ([TYPE_BLANK, TYPE_SELECTION].includes(type)) {
      const text = material.content?.Sentence?.text;
      let origin = material.content?.Sentence?.origin;
      const sentence = text || ERR_PLACEHOLDER;
      const word = material.content?.Word?.word;
      // 备选方案.
      if (!origin && text) {
        console.error('origin is not provided with id: ' + material.id);
        origin = text && word ? text.replace('___', word) : null;
      }
      const cnMeaning =
        window.language == 'zh' ? material.content?.Sentence?.cnMeaning : null;
      return (
        <Col className="d-flex align-items-center justify-content-center">
          <div className="center_child flex-column">
            <p>
              {sentence}
              {cnMeaning ? (
                <Icon.PatchQuestion
                  color={
                    indexOfShowingTranslation == indexOfMaterial
                      ? 'gray'
                      : 'yellow'
                  }
                  onClick={() => {
                    if (indexOfShowingTranslation == indexOfMaterial) return;
                    setTranslation(' ... ');
                    setIndexOfShowingTranslation(indexOfMaterial);
                    translate(origin || sentence);
                  }}
                />
              ) : null}{' '}
            </p>
            {indexOfShowingTranslation == indexOfMaterial ? (
              <p className="border border-secondary rounded text-secondary px-2">
                {' '}
                {translation}{' '}
              </p>
            ) : null}
          </div>

          <div style={{ position: 'absolute', top: '1em', right: '1em' }}>
            {hinterData ? (
              <Hinter
                word={hinterData.word}
                sentenceModel={hinterData.sentence}
              />
            ) : null}
          </div>
        </Col>
      );
    } else {
      return <div>待实现</div>;
    }
  }

  function createAnswer(material) {
    if (!material) throw new Error('material is not provided.');
    if (!material.type) throw new Error('material.type is not provided.');

    const { type, content, selections } = material;

    const ERROR_PLACEHOLDER = '**error**';

    if (type === 'audio') {
      return (
        <SubBlank
          correction={content || ERROR_PLACEHOLDER}
          clickInterceptor={clickInterceptor}
        />
      );
    } else if (TYPE_BLANK == type) {
      const word = content.Word?.word;
      return (
        <SubBlank
          correction={word || ERROR_PLACEHOLDER}
          clickInterceptor={clickInterceptor}
        />
      );
    } else if (TYPE_SELECTION == type) {
      const answer =
        content.Word?.word || content.Phrase?.text || ERROR_PLACEHOLDER;
      return (
        <SubSelection
          correction={answer}
          selectiveWords={selections}
          clickInterceptor={clickInterceptor}
        />
      );
    } else {
      return <div>待实现: {type}</div>;
    }
  }

  const warpPresenceAnimation = (comp) => {
    return (
      <AnimatePresence key="wrap_presence_animation_in_complex_learning_pager">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.3 }}
        >
          {comp}
        </motion.div>
      </AnimatePresence>
    );
  };

  const onKeyDown = (event) => {
    const { key, altKey, ctrlKey, shiftKey } = event;
    if (ctrlKey && key == '/') {
      event.preventDefault();
      setShowingSetting(true);
    } else if (ctrlKey && event.key == ',') {
      event.preventDefault();
      onClickedPrev();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    if (setId) {
      post(BACKEND_URLS.LEARNING_MATERIALS, {
        setId: setId,
        needSupplementary: true,
        // needWordPhrases: true,
        // needCollocations: true,
      })
        .then((response) => {
          console.log('data got response');
          setLoading(false);
          const ms = parseResult4LearningMaterials(response.data);
          setMaterials(ms);
          _setHinterByMaterial(ms[0]);
        })
        .catch((error) => {
          console.error(error);
          !showingResponseError && window.showErrorModal('' + error);
        })
        .finally(() => {
          console.log('data request done');
          setLoading(false);
        });
    }
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  if (loading) {
    return (
      <div className="d-flex align-items-center  justify-content-center">
        <Spinner animation="grow" />
      </div>
    );
  } else if (finished) {
    const count = correctCount();
    const resultUI = (
      <ExamResultUI correctCount={count} totalCount={materials.length || 0} />
    );
    return warpPresenceAnimation(resultUI);
  }

  if (!materials || materials.length === 0) {
    return <Nodata />;
  }

  const currentMaterial = materials[indexOfMaterial];
  const currentClipCpn = createClip(currentMaterial);
  const answerCpn = createAnswer(currentMaterial);

  const materialLen = materials?.length;
  return (
    <Container
      className="flex-column d-flex"
      key={'complex_learning_pager_container_index_' + indexOfMaterial}
      fluid
      style={{ height: maxHeight, fontSize: '1.2em' }}
    >
      {/* LineProgressBar */}
      <LineProgressBar progress={indexOfMaterial} total={materialLen || 100} />
      <div className="spacer" />
      {/* top line */}
      <Row className="d-flex flex-row">
        <Col md={2} sm={4}>
          <Button
            onClick={onClickedPrev}
            disabled={indexOfMaterial == 0}
            variant="outline-secondary"
          >
            <span>
              <Icon.ArrowLeft /> {t('上一个')}
            </span>
          </Button>
        </Col>
        <Col md={8} sm={4} className="text-center">
          {indexOfMaterial + 1}/{materialLen || 0}
        </Col>
        <Col md={2} sm={4}>
          <div className="justify-content-end align-items-end">
            <Button
              onClick={() => {
                setShowingSetting(true);
              }}
              variant="outline-secondary"
            >
              <span>
                <Icon.Sliders /> {t('自定义')}
              </span>
            </Button>
          </div>
        </Col>
      </Row>

      {/* content */}
      <Row className="d-flex flex-grow-1">
        <AnimatePresence key="wrap_presence_animation_in_complex_learning_pager_content">
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            className="d-flex align-items-center justify-content-center"
            transition={{ duration: 0.6 }}
          >
            {currentClipCpn}
          </motion.div>
        </AnimatePresence>
      </Row>
      <Row className="d-flex">
        <AnimatePresence key="wrap_presence_animation_in_complex_learning_pager_answer">
          <motion.div
            initial={{ opacity: 0, x: '100%' }}
            animate={{ opacity: 0.9, x: 0 }}
            exit={{ opacity: 0, x: '-100%' }}
            transition={{ duration: 0.6 }}
          >
            {answerCpn}
          </motion.div>
        </AnimatePresence>
      </Row>

      {showingSetting ? (
        <LearningOptionsModal
          show={showingSetting}
          onHide={() => {
            setShowingSetting(false);
          }}
        />
      ) : null}
    </Container>
  );
}
export default ComplexLearningUI;
