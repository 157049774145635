import React from 'react';
import { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import StudySetList from './StudySet/StudySetList';

// import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

function MyHomework() {
  // const { isAuthenticated, user, logout } = useAuth0();

  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('我的学习集');
  }, []);

  return (
    <Tabs fill defaultActiveKey="vocab_tab">
      <Tab eventKey="vocab_tab" title={t('词表')}>
        <StudySetList type={1} />
      </Tab>
      <Tab eventKey="phrase_tab" title={t('词组/固定搭配')}>
        <StudySetList type={2} />
      </Tab>
    </Tabs>
  );
}

export default MyHomework;
