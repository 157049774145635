/* eslint-disable no-unused-vars */
import React from 'react';

import * as Icon from 'react-bootstrap-icons';

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { checkArrType } from '../../../common/utils/FrontendTypeCheckers';
import bookIcon from './../../../book.svg';
import { useTranslation } from 'react-i18next';

/**
 * parse data to UI data. parse map to List with different types of items.
 * @param {Array} wordlibs - An array of word libraries.
 * @returns {Array} - An array of [string, {tag: list}].
 */
const parseWordLibs = (wordlibs) => {
  if (!wordlibs) {
    return [];
  }
  checkArrType(wordlibs);
  let result = [];
  for (let i = 0; i < wordlibs.length; i++) {
    const { title, list } = wordlibs[i];

    // a string will be render as a Title.
    result.push(title); // push a string.

    const map = new Map();
    for (let j = 0; j < list.length; j++) {
      const { id, name, description, length, tags } = list[j];

      for (let k = 0; k < tags.length; k++) {
        const tag = tags[k];
        if (!tag) throw new Error('one tag is empty in wordlibs.');
        if (!map.has(tag)) {
          map.set(tag, []);
        }
        // get an array and push the item into it.
        // on screen, the map data would be rendered into 2 lines, 1st is tags, 2nd is books or wordlibs.
        map.get(tag).push({
          id,
          name,
          description,
          size: length,
        });
      }
    }
    result.push(map); // push a map object.
  }

  return result;
};

// 这个页面不需要翻译。其他语言下不显示此页面
/**
 * 带三级 列表的页面
 * @param {*} props
 */
const WordLibUI = (props) => {
  const { t } = useTranslation();
  const { wordlibs, category, keyPrefix } = props;
  const uiDataList = parseWordLibs(wordlibs);
  const [selRecords, setSelRecords] = useState([]); // 选中的记录

  if (selRecords.length == 0) {
    const size = uiDataList.length;
    const newArr = [];
    // to push {size} zeroes into the selRecords.
    for (let i = 0; i < size; i++) {
      newArr.push(0);
    }
    setSelRecords(newArr);
  }

  return (
    <div>
      {uiDataList.map((item, index) => {
        if (item instanceof Map) {
          const keys = Array.from(item.keys());
          const keysSize = keys.length;
          return (
            <Col
              key={category + '_tag_' + index + '_parent'}
              className="border border-secondary p-2"
            >
              <div className="d-flex flex-wrap">
                {keys.map((key, indexOfRowItems) => {
                  return (
                    <Col
                      lg="3"
                      className="p-2"
                      key={keyPrefix + category + '_tag_' + indexOfRowItems}
                    >
                      <Button
                        onClick={() => {
                          selRecords[index] = indexOfRowItems;
                          setSelRecords([...selRecords]);
                        }}
                        className="rounded-pill"
                        variant={
                          selRecords[index] === indexOfRowItems
                            ? 'primary'
                            : 'outline-primary'
                        }
                      >
                        <span>{key}</span>
                      </Button>
                    </Col>
                  );
                })}
              </div>
              <Row>
                {keys.map((key, indexOfRowItems) => {
                  if (selRecords[index] === indexOfRowItems) {
                    return (
                      <Col
                        key={
                          keyPrefix +
                          category +
                          'sub_libs_' +
                          key +
                          indexOfRowItems
                        }
                      >
                        <Row className="gap-4">
                          {item.get(key).map((subitem, index) => {
                            return (
                              <Col
                                key={
                                  keyPrefix + category + 'sub_lib' + subitem.id
                                }
                              >
                                <div
                                  className="p-2 pe-3 bg-white bg-opacity-10 border border-light rounded  border-opacity-50"
                                  style={{ width: '240px' }}
                                  onClick={() => {
                                    props.onChooseLib(subitem);
                                  }}
                                >
                                  <Row className="d-flex  me-1 ps-3 fs-5 font-weight-bold">
                                    {subitem.name}
                                  </Row>

                                  <Row className="d-flex  align-items-center  justify-content-end">
                                    <img
                                      src={bookIcon}
                                      className="h-auto end-0"
                                      style={{ width: '100px' }}
                                      alt="book"
                                    />
                                  </Row>

                                  <Row className="d-flex me-1 justify-content-end text-body-secondary">
                                    {t('总数：') + subitem.size}
                                  </Row>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    );
                  } else {
                    return null;
                  }
                })}
              </Row>
            </Col>
          );
        } else if (typeof item === 'string') {
          return (
            <Row
              className="bg-secondary p-1"
              key={keyPrefix + category + '_strtitle_' + item + index}
            >
              <Col>
                <h4>
                  <span>
                    <Icon.Folder2Open />
                    <span className="ms-2">{item}</span>
                  </span>
                </h4>
              </Col>
            </Row>
          );
        } else {
          throw new Error('unexpected type in uiDataList.');
        }
      })}
    </div>
  );
};

WordLibUI.propTypes = {
  category: PropTypes.string.isRequired,
  wordlibs: PropTypes.array.isRequired, //从 Import.jsx 传入的 datalist
  onChooseLib: PropTypes.func.isRequired, //从 Import.jsx 传入的
  keyPrefix: PropTypes.string.isRequired,
};
export default WordLibUI;
