import React, { useEffect, useState } from 'react';
import {
  Row,
  Container,
  Button,
  Form,
  Dropdown,
  Spinner,
} from 'react-bootstrap';
import { showingResponseError } from '../../common/apis/requestHelper';
// import functions from  requestHelper
import { get } from '../../common/apis/requestHelper';
import URLPaths from '../../common/URLPaths';
/* eslint-disable no-unused-vars */
import LoadingNRetry from '../../common/ui/LoadingNRetry';
import { checkArrType } from '../../common/utils/FrontendTypeCheckers';
// import translation
import { useTranslation } from 'react-i18next';

const PagerTag = 'AITeacher';
import styles from './AITeacher.module.css';

import WSClient from '../../common/apis/WSClient';
import { readToken } from '../../common/apis/UserInfoHelper';
import CustomHelmet from '../../common/ui/CustomHelmet';

/**
 * 对应/mine/ai-teacher路径，用于向AI老师提问
 */
let wsClient = null;
const messages = [];
function AITeacher() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [selQuestion, setSelQuestion] = useState(undefined);
  const [inputText, setInputText] = useState('');

  const [msgLen, setMsgLen] = useState(0);
  const [streamTxt, setStreamTxt] = useState(''); //this state is only used for trigger render.
  const [isAIWriting, setIsAIWriting] = useState(false);

  const onGotWsError = (event) => {
    console.error('WebSocket error:', event.message);
  };
  const onGotMsgFromWS = (rspstr) => {
    const { isDone, isError, code, msg, text, stream } = JSON.parse(rspstr);

    // if(code== ?) TODO post msg to sentry.

    const { author, exception } = messages[0] || {};
    // if (author == 'teacher') messages.shift();

    messages.forEach((item) => {
      item.isLoading && (item.isLoading = false);
    });

    // TODO lastMsg.isLoading was not tested.
    if (!isError) {
      let lastMsg = messages[messages.length - 1];
      if (lastMsg.author != 'teacher') {
        lastMsg = { text: '', author: 'teacher' };
        messages.push(lastMsg);
      }
      if (stream && text) {
        lastMsg.text += text;
        setStreamTxt(lastMsg.text);
        lastMsg.isLoading = true;
        setMsgLen(messages.length);
        setIsAIWriting(true);
      } else {
        lastMsg.isLoading = false;
        setStreamTxt('done');
        setIsAIWriting(false);
      }
    } else {
      setIsAIWriting(false);
      let lastMsg = messages[messages.length - 1];

      if (lastMsg.author != 'teacher') {
        lastMsg = {
          text: text || t('AI异常'),
          author: 'teacher',
          exception: true,
        };
        messages.push(lastMsg);
      }
      lastMsg.isLoading = false;
      lastMsg.exception = true;
      setMsgLen(messages.length);
    }
  };

  const requestQuestions = () => {
    !loading && setLoading(true);

    get(URLPaths.CHAT_QUESTION_LIST)
      .then((response) => {
        if (response.data) {
          checkArrType(response.data, 'response.data');
          setQuestions(response.data);
          setFailed(false);

          if (!wsClient) {
            wsClient = new WSClient(
              URLPaths.WS_CHAT,
              onGotWsError,
              onGotMsgFromWS
            );
          }
          if (!wsClient.isConnected()) {
            wsClient.connect();
          }
        } else {
          setFailed(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setFailed(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!questions || questions.length === 0) {
      requestQuestions();
    }

    return () => {
      console.warn('useEffect and disconnect ' + PagerTag);
      // wsClient && wsClient.disconnect();
    };
  }, [messages, wsClient, streamTxt]);

  // handler for send button
  function handleSendClick() {
    if (!inputText) return;

    const token = readToken();
    if (!token) {
      !showingResponseError && window.showErrorModal(t('请先登录'));
      return;
    }

    const { include } = selQuestion || {};
    if (include && inputText.indexOf(include) === -1) {
      window.showErrorModal(
        t('msg_of_need_keyword_in_ai', { keyword: include })
      );
      return;
    }

    if (!wsClient.isConnected()) {
      wsClient.connect();
    }

    const msgObj = {
      inputMsg: inputText,
      questionId: selQuestion.id,
      token,
      lan: window.language,
    };

    const delayMS = wsClient.isConnected() ? 10 : 1000;

    setTimeout(() => {
      if (!wsClient.isConnected()) {
        window.showErrorModal('连接已断开，请刷新页面重试。');
        return;
      }

      const sent = wsClient && wsClient.sendMessage(msgObj);

      messages.push({ text: inputText, author: 'user', isLoading: sent });

      if (!sent) {
        window.showErrorModal('发送失败，请刷新页面重试。');
        return;
      }

      setMsgLen(messages.length);
    }, delayMS);
    setInputText('');
  }

  // handler for input change
  function handleInputChange(event) {
    setInputText(event.target.value);
  }

  // handler for question button
  function handlerSelect(eventKey) {
    const index = parseInt(eventKey.replace('#/', ''));
    const q = questions[index];
    messages.splice(0, messages.length);
    setSelQuestion(q);
  }

  const helmetJSX = <CustomHelmet currentMeta={window.metas.ai} />;

  if (loading || failed) {
    return (
      <div>
        {helmetJSX}
        <LoadingNRetry
          key="AI-teacher-loading-n-retry"
          loading={loading}
          failed={failed}
          retry={requestQuestions}
        />
      </div>
    );
  }

  let title = `  ${t('请选择问题')}  `;
  let desc = title;
  if (selQuestion) {
    desc = window.language == 'zh' ? selQuestion.cnDesc : selQuestion.enDesc;
    title = window.language == 'zh' ? selQuestion.cnTitle : selQuestion.enTitle;
  }

  return (
    <div>
      {helmetJSX}

      {/*消息框*/}
      <Container
        style={{
          minHeight: 500,
          background: '#1a1d20',
          borderRadius: 10,
          marginBottom: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {/** top bar **/}
        <Row
          style={{
            backgroundColor: '#343a40',
            border: '1px solid #343a40',
            borderRadius: '10px',
            padding: '5px',
          }}
        >
          <div className="d-flex align-items-center  justify-content-between">
            <p className="text-wrap text-break">{desc}</p>
            {/*问题选择按钮*/}
            <Dropdown
              variant="secondary"
              className="d-flex justify-content-end"
              onSelect={handlerSelect}
            >
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                {title}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Array.isArray(questions) &&
                  questions.map((item, index) => {
                    return (
                      <Dropdown.Item
                        href={'#/' + item.id}
                        key={index}
                        eventKey={index}
                      >
                        {window.language == 'zh' ? item.cnTitle : item.enTitle}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Row>

        <div
          className="d-flex flex-column flex-grow-1"
          style={{
            overflowY: 'auto',
            maxHeight: 700,
          }}
        >
          {messages.map((item, index) => {
            return (
              <div
                key={'ai-teacher-chat-history-' + index}
                className={
                  item.author == 'user'
                    ? 'd-flex align-items-end flex-column'
                    : 'd-flex flex-column'
                }
              >
                <div
                  className={
                    item.author == 'user'
                      ? styles.message_bar_right
                      : styles.message_bar_left
                  }
                >
                  {item.text.split('\n').map((line, index) => {
                    //because '\n' doesnt work in div, i need to render text line and br
                    return (
                      <div key={'ai-chat-newline-fix-' + index}>
                        {line}
                        <br style={{ lineHeight: '0.5em' }} />
                      </div>
                    );
                  })}
                </div>
                <span className={styles.message_time}>
                  {item.isLoading ? (
                    <Spinner animation="grow" size="sm" variant="light" />
                  ) : null}
                  {item.isLoading ? (
                    <Spinner animation="grow" size="sm" variant="light" />
                  ) : null}
                  {item.isLoading ? (
                    <Spinner animation="grow" size="sm" variant="light" />
                  ) : null}

                  {t(item.author == 'user' ? '我' : '老师')}
                </span>
              </div>
            );
          })}
        </div>

        <div className="d-flex justify-content-between">
          {/*消息输入框*/}
          <div className="flex-grow-1 me-4">
            <Form.Group
              controlId="exampleForm.ControlTextarea1"
              className="d-flex"
            >
              <Form.Control
                maxLength={100}
                value={inputText}
                onChange={handleInputChange}
              />
            </Form.Group>
          </div>

          {/*发送按钮*/}
          <Button
            disabled={!selQuestion || !inputText || isAIWriting}
            variant="primary"
            onClick={handleSendClick}
          >
            {'  ' + t('发送') + '  '}
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default AITeacher;
