import React from 'react';
import * as Icon from 'react-bootstrap-icons';

import { useTranslation } from 'react-i18next';
const maxHeight = window.innerHeight - 120;
const Nodata = () => {
  const { t } = useTranslation();

  return (
    <div
      className="d-flex justify-content-center"
      style={{ height: maxHeight + 'px', fontSize: '1.2em' }}
    >
      <div className="d-flex flex-column align-items-center justify-content-center">
        <Icon.Search size={'5em'} />
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {t('无数据，请导入单词,或等待AI生成')}
        </span>
      </div>
    </div>
  );
};
export default Nodata;
