/* eslint-disable no-unused-vars */
import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { FlashcardArray } from 'react-quizlet-flashcard';

import { isPagerComplete } from '../../../common/utils/WebPagerHelper';
import { Card, Col, Container, Row, Button } from 'react-bootstrap';

import * as Icon from 'react-bootstrap-icons';
import { NavLink, Redirect } from 'react-router-dom';

import BACKEND_URLS from '../../../common/URLPaths';

import {
  getFlashcardSetting,
  saveFlashcardSetting,
} from './FullscreenFlashcardOptionsStorage';
import Spinner from 'react-bootstrap/Spinner';
import {
  get,
  post,
  showingResponseError,
} from '../../../common/apis/requestHelper';
import CustomFlashcard from './CustomFlashCard';
import RoutePaths from '../../../RoutePaths';

import FullscreenFlashcardOptionsModal from './FullscreenFlashcardOptionsModal';

import { useParams, useHistory, useLocation } from 'react-router-dom';
import { readUser } from '../../../common/apis/UserInfoHelper';
import { parseResult4FlashcardMaterials } from './FullscreenFlashcardMaterialHandler';
import ExamResultUI from '../Clips/ExamResultUI';
import { fetchTextAudio, speech } from '../../../common/utils/TTSUtils';
// import styles from './FullscreenFlashcard.module.scss';
import { useTranslation } from 'react-i18next';

//该文件里没有注册 键盘事件的监听。
function FullscreenFlashCard() {
  const { t } = useTranslation();
  const params = useParams();
  const [innerWidth, setInnerWidth] = useState(1000);

  const [loading, setLoading] = useState(true);
  const [materials, setMaterials] = useState(undefined);

  const [showingSetting, setShowingSetting] = useState(false);
  const [finished, setFinished] = useState(false);
  const [correctCount, setCorrectedCount] = useState(0);

  const maxHeight = window.innerHeight - 120;

  const setId = params.setId;

  if (!setId) {
    console.error('setId is not provided.');
    window.showErrorModal(t('数据异常'));
    return <div>setId is not provided.</div>;
  }

  // 定义宽度变化时的处理逻辑
  const handleResize = () => {
    const element = document.getElementById('app_container');
    // to set the width of the list.
    let width = Math.max(element.offsetWidth, window.width);
    if (width < 10) {
      width = 1000;
    }
    const sidePadding = 15;
    setInnerWidth(width - sidePadding * 2);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    if (setId) {
      post(BACKEND_URLS.LEARNING_MATERIALS, {
        setId,
      })
        .then((response) => {
          let tmpMaterials = parseResult4FlashcardMaterials(response.data);
          setMaterials(tmpMaterials);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          !showingResponseError &&
            window.showErrorModal('' + error, () => {
              setLoading(false);
            });
        });
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (loading) {
    return (
      <div className="d-flex align-items-center  justify-content-center">
        <Spinner animation="grow" />
      </div>
    );
  } else if (finished) {
    return (
      <ExamResultUI correctCount={correctCount} totalCount={materials.length} />
    );
  }

  if (!materials) {
    return <Redirect to={RoutePaths.HOME} />;
  }

  return (
    <Container
      className="flex-column d-flex"
      key={'fullscreenflashcard_pager_container'}
      fluid
      style={{ height: maxHeight, fontSize: '1.2em' }}
    >
      <div className="spacer" />
      {/* top line */}
      <Row className="d-flex flex-row">
        <Col md={2} sm={4}>
          <Button
            variant="outline-secondary"
            onClick={() => {
              history.back();
            }}
          >
            <span>
              <Icon.ArrowLeft /> {t('返回')}
            </span>
          </Button>
        </Col>
        <Col md={8} sm={4} className="text-center">
          {/* 闪卡 */}
        </Col>
        <Col md={2} sm={4}>
          <div className="justify-content-end align-items-end text-align-end">
            <Button
              onClick={() => {
                setShowingSetting(true);
              }}
              variant="outline-secondary"
            >
              <span>
                <Icon.Sliders /> {t('自定义')}
              </span>
            </Button>
          </div>
        </Col>
      </Row>

      {/* content */}
      <div
        className="d-flex align-items-center  justify-content-center"
        style={{ height: maxHeight }}
      >
        <CustomFlashcard
          innerWidth={innerWidth}
          sentences={materials}
          onFinished={(count) => {
            setCorrectedCount(count);
            setFinished(true);
          }}
          onFlipped={(index) => {
            console.log('onFlipped');
            const { autoPlayAudio } = getFlashcardSetting();
            const currentMaterial = materials[index];
            const word = currentMaterial.content.Word?.word;
            if (word && autoPlayAudio && currentMaterial.type == 'sentence') {
              speech(currentMaterial.content.Word?.word);
            }
          }}
        />
      </div>

      {showingSetting ? (
        <FullscreenFlashcardOptionsModal
          show={showingSetting}
          onHide={() => {
            setShowingSetting(false);
          }}
        />
      ) : null}
    </Container>
  );
}

export default FullscreenFlashCard;
