// use react-component to draw a result of exam, which has pir chart."thumb-up" and "not bad" message.
// to render this component, I will add props.correctCount, props.totalCount.
// The class name is ExamResultUI.
/* eslint-disable no-unused-vars */
import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { FlashcardArray } from 'react-quizlet-flashcard';
import { Link } from 'react-router-dom';

import { useHistory } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// to import Row, Col
import { Card, Col, Row, Container, Button } from 'react-bootstrap';

import useFireWork from 'react-use-firework';

import PropTypes from 'prop-types';
import RoutePaths from '../../../RoutePaths';
import { useTranslation } from 'react-i18next';

//这个UI组件上的两个按钮，点击后，都会触发页面重载/跳转。 那么点击之前的内存中的状态，都会丢失。
function ExamResultUI(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const score = props.correctCount / props.totalCount;

  const ref = useRef(null);
  useFireWork(ref);

  const maxHeight = window.innerHeight - 120;

  return (
    <Container style={{ height: maxHeight + 'px' }} className="center_child">
      <Col>
        <Row></Row>

        <Row>
          <Col md={1} />
          <Col md={5}>
            <Row>
              <h3>你的成绩是: </h3>
            </Row>
            {/* to draw a pi chart */}
            <Row>
              <div className="center_child">
                <div
                  style={{ width: 200, height: 200 }}
                  className="center_child"
                  ref={ref}
                >
                  <CircularProgressbar
                    styles={buildStyles({
                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 1,
                      // Colors
                      textColor: 'white',
                      trailColor: '#333',
                      backgroundColor: 'red',
                      pathColor: '#b06c25',
                    })}
                    text={`${props.correctCount}/${props.totalCount}`}
                    value={props.correctCount}
                    maxValue={props.totalCount}
                  />
                </div>
              </div>
            </Row>
          </Col>
          <Col md={5}>
            {/* to draw a pi chart */}
            <div className="spacer" />
            <Row>
              <Button
                variant="primary"
                size="lg"
                onClick={() => {
                  history.push(window.location.pathname);
                }}
              >
                <span>
                  <Icon.ArrowClockwise /> {t('再试一次')}
                </span>
              </Button>
            </Row>
            <div className="spacer" />
            <div className="spacer" />
            <div className="spacer" />
            <div className="spacer" />
            <div className="spacer" />
            <div className="spacer" />
            <Row>
              <Button
                variant="outline-light"
                onClick={() => {
                  history.push(RoutePaths.mergeMine(RoutePaths.SUB_HOMEWORK));
                }}
              >
                <span>
                  <Icon.ListStars /> {t('回到学习集列表')}
                </span>
              </Button>
            </Row>
            <div className="spacer" />
          </Col>
          <Col md={1} />
        </Row>
      </Col>
    </Container>
  );
}

ExamResultUI.propTypes = {
  correctCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
};
export default ExamResultUI;
