/* eslint-disable no-unused-vars */

import PropTypes from 'prop-types';
import React from 'react';
import { useEffect, useState, useRef } from 'react';

import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from './Clips.module.css';

import useFireWork from 'react-use-firework';

// define  a function to throw Error in case of invalid props.
const propsCheck = (props) => {
  if (!props.selectiveWords) {
    throw new Error('selectiveWords is required.');
  }
  if (!props.correction) {
    throw new Error('correction is required.');
  }

  const { selectiveWords, correction } = props;

  let correctIndex = -1;
  selectiveWords.forEach((word, index) => {
    if (word === correction) {
      correctIndex = index;
    }
  });

  if (correctIndex < 0 || correctIndex >= selectiveWords.length) {
    throw new Error(
      'correctIndex is out of range from ' + JSON.stringify(selectiveWords)
    );
  }
};

// create a map for rendering: 🄋	➀	➁	➂	➃	➄	➅	➆	➇	➈	➉
const tenNumbersMap = new Map();
tenNumbersMap.set(0, '🄋');
tenNumbersMap.set(1, '➀');
tenNumbersMap.set(2, '➁');
tenNumbersMap.set(3, '➂');
tenNumbersMap.set(4, '➃');
tenNumbersMap.set(5, '➄');

import i18next from 'i18next';
const { t } = i18next;
function createPTag2ShowCorrection(correction) {
  if (!correction) return '';

  return (
    <p key={'subselection_correction_p'} className={styles.none_margin}>
      <span style={{ color: 'gray' }}>{t('正确答案是：')}</span>
      <span style={{ color: 'red' }}>{correction}</span>
    </p>
  );
}

let correctionHider;
// this is a component to show the selection exercise.
// ---------------------------------------------------------------
// In my expectation: the content of every selection is a word. It is too short.
// Otherwise, the UI would be changed to be weird.
// ---------------------------------------------------------------
// The height of this piece of UI should be half of the screen.
import { useTranslation } from 'react-i18next';
const SubSelection = (props) => {
  const { t } = useTranslation();
  const [usersChoice, setUsersChoice] = useState(-1); // the index of the word that user choose.
  const [showingCorrection, setShowCorrection] = useState(false);

  const ref = useRef(null);
  useFireWork(ref);

  const unfamiliar = () => {
    if (clickInterceptor(false)) return;
    setShowCorrection(true);

    correctionHider = setTimeout(() => {
      setShowCorrection(false);
    }, correction.length * 1000);
  };

  const onKeyDown = (event) => {
    // console.log('event: ' + event.key + '  ' + event.altKey + '  ' + event.ctrlKey + '  ' + event.shiftKey);
    const { key, altKey, ctrlKey, shiftKey } = event;
    if (altKey == true || shiftKey == true) {
      return;
    }
    if (ctrlKey && key == 'u') {
      event.preventDefault();
      unfamiliar();
    }
  };

  useEffect(() => {
    propsCheck(props);
    window.addEventListener('keydown', onKeyDown);

    return () => {
      clearTimeout(correctionHider);
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const { selectiveWords, correction, clickInterceptor } = props;

  let correctIndex = 0;
  selectiveWords.forEach((word, index) => {
    if (word === correction) {
      correctIndex = index;
    }
  });

  const selectionClickHandle = (index) => {
    if (usersChoice >= 0) return;

    if (index == undefined) {
      window.showErrorMessage('系统错误：' + ' wrong index.');
      return;
    }

    setUsersChoice(index);

    if (index !== correctIndex) {
      setShowCorrection(true);
    }

    if (clickInterceptor(index == correctIndex)) {
      return;
    }
  };

  const renderingRowsData = [];
  // parse every two words to obj into renderingArr, those objects would be like: like {left: {index, word}, right: **}.
  for (let i = 0; i < selectiveWords.length; i += 2) {
    const left = { index: i, word: selectiveWords[i] };
    let right = undefined;
    if (i + 1 < selectiveWords.length) {
      right = { index: i + 1, word: selectiveWords[i + 1], correct: true };
    }
    renderingRowsData.push({ left, right });
  }

  // in the code below, I render every two words in a row. In some cases, the last row doesn't exist.
  return (
    <div style={{ display: 'flex' }}>
      <Col>
        <Row>
          {showingCorrection ? createPTag2ShowCorrection(correction) : null}
        </Row>

        {renderingRowsData.map((item, index) => {
          const { left, right } = item; // the right may be undefined.
          if (index >= 2) return null; // only show the first two rows, which must contain 4 words.

          const hasChoose = usersChoice >= 0;
          const isLeftCorrect =
            (hasChoose || showingCorrection) && left.index === correctIndex;
          const isRightCorrect =
            (hasChoose || showingCorrection) &&
            right &&
            right.index === correctIndex;
          return (
            <Row
              key={index}
              className="align-items-center  justify-content-between"
            >
              <Col
                md={5}
                ref={left.index == correctIndex ? ref : null}
                onClick={() => {
                  selectionClickHandle(left.index);
                }}
                className={
                  isLeftCorrect ? styles.blue_border : styles.gray_border
                }
              >
                {tenNumbersMap.get(left.index + 1)}
                {' ' + left.word}
              </Col>
              {right ? (
                <Col
                  md={5}
                  ref={
                    right.index == correctIndex && !showingCorrection
                      ? ref
                      : null
                  }
                  onClick={() => {
                    selectionClickHandle(right.index);
                  }}
                  className={
                    isRightCorrect ? styles.blue_border : styles.gray_border
                  }
                >
                  {tenNumbersMap.get(right.index + 1)}
                  {' ' + right.word}
                </Col>
              ) : null}
            </Row>
          );
        })}

        <Row className="align-items-end">
          <div className="d-flex justify-content-end">
            <Button
              disabled={usersChoice >= 0}
              variant="warning"
              onClick={unfamiliar}
            >
              {t('不知道')}
            </Button>
            <div className="m-2" />
            <Button
              disabled={usersChoice < 0}
              onClick={() => {
                clickInterceptor(true);
              }}
            >
              {t('下一步')}
            </Button>
          </div>
        </Row>
      </Col>
    </div>
  );
};

SubSelection.propTypes = {
  selectiveWords: PropTypes.array.isRequired,
  correction: PropTypes.string.isRequired,
  clickInterceptor: PropTypes.func.isRequired,
};

export default SubSelection;
