/* eslint-disable no-unused-vars */
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

import {
  get,
  post,
  showingResponseError,
} from '../../common/apis/requestHelper';

import PropTypes from 'prop-types';

import styles from './DeletedStudySets.module.css';
import Spinner from 'react-bootstrap/Spinner';

import { useHistory } from 'react-router-dom';

import TrashItems from './TrashItems';

//import infinite list.
import 'react-virtualized/styles.css';
import Container from 'react-bootstrap/Container';
import { Grid } from 'react-virtualized';
import { List, AutoSizer } from 'react-virtualized';

// import auth0
import { useAuth0 } from '@auth0/auth0-react';
import { isPagerComplete } from '../../common/utils/WebPagerHelper';
import {
  readToken,
  readTokenWithDialog,
  readUser,
  writeUser,
} from '../../common/apis/UserInfoHelper';

import { useTranslation } from 'react-i18next';
import BACKEND_URLS from '../../common/URLPaths';
import {
  checkArrType,
  checkNonEmptyArray,
} from '../../common/utils/FrontendTypeCheckers';

// to draw
const DeletedStudySets = (props) => {
  const { t } = useTranslation();
  const { type } = props;

  const containerRef = useRef(null);
  const [isRequesting, setIsRequesting] = useState(true);
  const [datalist, setDatalist] = useState([]);

  const { user } = useAuth0();

  if (user?.id) {
    writeUser(user);
  }

  useEffect(() => {
    if (!isPagerComplete()) {
      return;
    }

    // get my study set.
    setTimeout(async () => {
      const showErrorModal = window.showErrorModal;

      const url =
        type == 1
          ? BACKEND_URLS.MY_STUDYSET_PATH
          : BACKEND_URLS.MY_PHRASE_LIST_PATH;
      post(url, {
        onlyDeleted: true,
      })
        .then((res) => {
          if (res.code === 0) {
            console.log('res' + JSON.stringify(res.data));
            checkArrType(res.data);
            setDatalist(res.data);
          } else {
            console.error('DeletedStudySets failed.');
            console.error('http code: ' + res.code);
            !showingResponseError &&
              showErrorModal(t('数据异常，请稍后重试。'));
          }
        })
        .catch((error) => {
          !showingResponseError && showErrorModal(t('数据异常，请稍后重试。'));
          console.error('DeletedStudySets failed.');
          console.error(error);
        })
        .finally(() => {
          setIsRequesting(false);
        });
    }, 100);
  }, []);

  return (
    <Container
      className={styles.studyset_container}
      ref={containerRef}
      id={'trash-grid-container-' + type}
      style={{ flex: '1 1 auto', minHeight: 800 }}
    >
      {isRequesting ? (
        <div className="d-flex align-items-center  justify-content-center">
          <Spinner animation="grow" />
        </div>
      ) : null}

      {!isRequesting && datalist.length === 0 ? (
        <div className="d-flex align-items-center  justify-content-center">
          <p>{t('回收站为空')}</p>
        </div>
      ) : (
        <TrashItems datalist={datalist} type={type} />
      )}
    </Container>
  );
};

DeletedStudySets.propTypes = {
  type: PropTypes.number.isRequired,
};

export default DeletedStudySets;
