import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';

import {
  getFlashcardSetting,
  saveFlashcardSetting,
  setFeatureEnable,
  OPT_FEATURES,
} from './FullscreenFlashcardOptionsStorage';

import { Col, Row, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  checkBooleanType,
  checkStringType,
} from '../../../common/utils/FrontendTypeCheckers';

import { Shortcut, FullscreenFlashcardShortcuts } from '../Clips/Shortcut';

// only stared words item would depend on how many words stared.
import { useTranslation } from 'react-i18next';
function FullscreenFlashcardOptionsModal(props) {
  const { t } = useTranslation();
  const { show, onHide } = props;
  const [hasChanged, setHasChanged] = useState(false);
  const history = useHistory();

  const createLeftText = (text) => {
    return <Col md={8}> {text} </Col>;
  };

  // const randomInt = (min, max) => {
  //     return Math.floor(Math.random() * (max - min + 1) + min);
  // };

  const createRightSwitch = (id, inputChecked, func) => {
    checkBooleanType(inputChecked);
    checkStringType(id);

    return (
      <Col className="text-end" md={4}>
        <Form.Check
          checked={inputChecked}
          onChange={({ target: { checked } }) => {
            console.log(id + ': ' + checked);
            checkBooleanType(checked);
            func && func(checked);
            setHasChanged(true);
            // setTimeout(() => {
            //     setRandomNumber(randomInt(0, 100));
            // }, 200);
          }}
          type="switch"
          id={id}
        />
      </Col>
    );
  };

  /**    
    autoPlayAudio = false;
    useKeyboard = false;
    onlyStared = false;
    shuffle = false;
    enableMeanings = false;
    relateImages = false;
     */
  const { shuffle, autoPlayAudio, useKeyboard, onlyStared, enableMeanings } =
    getFlashcardSetting();

  const [shuffleState, setShuffleState] = useState(shuffle);
  const [autoPlayAudioState, setAutoPlayAudioState] = useState(autoPlayAudio);
  const [useKeyboardState, setUseKeyboardState] = useState(useKeyboard);
  const [onlyStaredState, setOnlyStaredState] = useState(onlyStared);
  const [enableMeaningsState, setEnableMeaningsState] =
    useState(enableMeanings);
  // const [relateImagesState, setRelateImagesState] = useState(relateImages);

  return (
    <Modal
      onHide={onHide}
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>学习方式偏好</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ fontSize: '1.2em' }}>
        <Col key={'leanring_modal_body_col_1'}>
          {/* automatically play audio*/}

          <Row>
            {createLeftText('音频自动播放')}
            {createRightSwitch(
              'autoPlayAudio',
              autoPlayAudioState,
              (boolValue) => {
                checkBooleanType(boolValue);
                setFeatureEnable(OPT_FEATURES.AUTOPLAY, boolValue);
                setAutoPlayAudioState(boolValue);
              }
            )}
          </Row>

          {/* keyboard */}
          <Row>
            {createLeftText('使用快捷键')}
            {createRightSwitch('useKeyboard', useKeyboardState, (boolValue) => {
              setFeatureEnable(OPT_FEATURES.KEYBOARD, boolValue);
              setUseKeyboardState(boolValue);
            })}
          </Row>

          {/* shortcuts card */}
          <Shortcut shortcuts={FullscreenFlashcardShortcuts} />

          {/* only stared */}
          <Row>
            {createLeftText('只学收藏句子')}
            {createRightSwitch('onlyStared', onlyStaredState, (boolValue) => {
              setFeatureEnable(OPT_FEATURES.STARRED, boolValue);
              setOnlyStaredState(boolValue);
            })}
          </Row>

          {/* shuffle */}
          <Row>
            {createLeftText('随机')}
            {createRightSwitch('shuffle', shuffleState, (boolValue) => {
              setFeatureEnable(OPT_FEATURES.SHUFFLE, boolValue);
              setShuffleState(boolValue);
            })}
          </Row>

          {/* enable meanings */}
          <Row>
            {createLeftText('展示释义')}
            {createRightSwitch(
              'enableMeanings',
              enableMeaningsState,
              (boolValue) => {
                checkBooleanType(boolValue);
                setFeatureEnable(OPT_FEATURES.MEANING, boolValue);
                setEnableMeaningsState(boolValue);
              }
            )}
          </Row>
        </Col>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex align-items-end justify-content-end">
          <h5
            className={hasChanged ? 'text-primary' : 'text-muted'}
            onClick={() => {
              saveFlashcardSetting();
              setTimeout(() => {
                history.push(document.location.pathname);
              }, 100);
            }}
          >
            {t('重启')}
          </h5>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
FullscreenFlashcardOptionsModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};
export default FullscreenFlashcardOptionsModal;
