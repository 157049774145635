import {
  checkArrType,
  checkNonEmptyObject,
} from '../../../common/utils/FrontendTypeCheckers';

import {
  getPhraseCacheManager,
  getWordRecordCacheManager,
} from '../utils/ManagerGetter';

// TODO import { getListeningSetting } from './ListeningOptionsStorage';

// It is used to limit the count of words/phrases in the result.
const LEN_OF_RESULT = 30;

export const TYPE_WORD_WITH_AUDIO = 'word_audio';
export const TYPE_PHRASE_WITH_AUDIO = 'phrase_audio';

/**
 * 该函数用于返回听力的素材。
 * 从数据库中获取的数据，需要经过这个函数处理，才能用于前端显示。以便预先处理一些之前答错的数据。
 *
 */

/**
 * @param rspData.materials be like:
 * [
      {
        type: TYPE_WORD,
        word: item.word,
        id: item.id,
      },
      {
        type: TYPE_PHRASE,
        text: item.text,
        cnMeaning: item.cnMeaning,
        word: 'xxxx',
        id: item.id,
      },
      {
        type: TYPE_PHRASE,
        text: item.text,
        cnMeaning: item.cnMeaning,
        word: null,
        id: item.id,
      }
    ]
 * @returns Array like:[ {type: 'word_audio', content: {} }, {type: 'phrase_audio', content: {}}}]
 *
 * 该函数除了用于array 结构转换，还处理了“乱序逻辑” , "优先学习错误的words/phrases"
 */
export function parseResult4ListeningMaterials(rspData) {
  checkNonEmptyObject(rspData);
  //TODO read similarWordMap from rspData.
  const { materials } = rspData;
  checkArrType(materials);
  if (materials.length < 1) return [];

  //TODO
  // const { shuffle, onlyStared } = getListeningSetting();

  const renderingMaterials = [];

  // 1st, to seperate words and phrases, into different arrays.
  // words arrays
  const wrongWords = [];
  const sucWords = [];
  const unlearntWords = [];
  // phrases arrays
  const phrases = [];

  materials.forEach((item) => {
    item.ttsText = item.text || item.word || '***error***';
    if (item.type == 'word') {
      if (getWordRecordCacheManager().getFailureCount(item.id) > 0) {
        wrongWords.push(item);
      } else if (getWordRecordCacheManager().getSucCount(item.id) > 0) {
        sucWords.push(item);
      } else {
        unlearntWords.push(item);
      }
    } else if (item.type == 'phrase') {
      if (getPhraseCacheManager().getFailureCount(item.id) > 0) {
        phrases.unshift(item);
      } else {
        phrases.push(item);
      }
    }
  });

  //2nd, to put items into renderingMaterials.
  for (let i = 0; i < wrongWords.length; i++) {
    if (renderingMaterials.length >= LEN_OF_RESULT / 2) break;
    const item = wrongWords[i];
    renderingMaterials.push({ type: TYPE_WORD_WITH_AUDIO, content: item });
  }
  for (let i = 0; i < unlearntWords.length; i++) {
    if (renderingMaterials.length >= LEN_OF_RESULT / 2) break;
    const item = unlearntWords[i];
    renderingMaterials.push({ type: TYPE_WORD_WITH_AUDIO, content: item });
  }

  // shuffle those words before push suc words.
  // shuffleArray(renderingMaterials);
  for (let i = 0; i < sucWords.length; i++) {
    if (renderingMaterials.length >= LEN_OF_RESULT / 3) break;
    const item = sucWords[i];
    renderingMaterials.push({ type: TYPE_WORD_WITH_AUDIO, content: item });
  }

  // if (shuffle) {
  // }

  // use this variable to limit the count of words. phrase is no need to shuffle
  if (renderingMaterials.length < LEN_OF_RESULT) {
    for (let i = 0; i < phrases.length; i++) {
      if (renderingMaterials.length >= LEN_OF_RESULT) break;
      const item = phrases[i];
      renderingMaterials.push({ type: TYPE_PHRASE_WITH_AUDIO, content: item });
    }
  }

  console.log(
    'parseResult4ListeningMaterials# renderingMaterials: ',
    renderingMaterials
  );
  shuffleArray(renderingMaterials);
  return renderingMaterials;
}

export default {
  parseResult4ListeningMaterials,
  TYPE_WORD_WITH_AUDIO,
  TYPE_PHRASE_WITH_AUDIO,
};

// ----------shuffleArray ------------

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}
