import React, { useEffect, useState } from 'react';
import classes from './Welcome.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';
import { useAuth0 } from '@auth0/auth0-react';

import { motion } from 'framer-motion';

import Footer from '../Footer/Footer';

// 这个jsx会用在首页，这里的内容将会影响SEO。
// 这个页面的多数关键字推荐使用 <strong> 去包裹。 due to the instruction: https://www.velizaratellalyan.com/seo/the-importance-of-bold-and-strong-tags-in-seo/

import { useTranslation } from 'react-i18next';
import CustomHelmet from '../../common/ui/CustomHelmet';
function Welcome() {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState('');

  const { isAuthenticated } = useAuth0();
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {}, []);

  return (
    <Container fluid>
      <CustomHelmet currentMeta={window.metas.home} />
      {/*我们解决什么问题*/}
      <Col>
        <div className="spacer" />
        <Row>
          <h1 className="h2">{t('welcome_problem_title')}</h1>
        </Row>
        <div className="spacer" />

        <div className="spacer" />
        <Row>
          {/*problem1*/}
          <Col>
            <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
              <Card className={classes.tilted_rects_bg_card_right}>
                <Card.Body>
                  <Col>
                    <h4>{t('welcome_problem_subtitle1')}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t(`welcome_problem_subtitle1_desc1`),
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t(`welcome_problem_subtitle1_desc2`),
                      }}
                    />
                  </Col>
                </Card.Body>
              </Card>
            </motion.div>
          </Col>

          {/*problem2*/}
          <Col>
            <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
              <Card className={classes.tilted_rects_bg_card_left}>
                <Card.Body>
                  <Col>
                    <h4>{t('welcome_problem_subtitle2')}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t(`welcome_problem_subtitle2_desc1`),
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t(`welcome_problem_subtitle2_desc2`),
                      }}
                    />
                  </Col>
                </Card.Body>
              </Card>
            </motion.div>
          </Col>
        </Row>

        <div className="spacer" />
        <div className="spacer" />
        <Row>
          {/*problem3*/}
          <Col>
            <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
              <Card className={classes.tilted_dots_bg_card_right}>
                <Card.Body>
                  <Col>
                    <h4>{t('welcome_problem_subtitle3')}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t(`welcome_problem_subtitle3_desc1`),
                      }}
                    />
                  </Col>
                </Card.Body>
              </Card>
            </motion.div>
          </Col>
          {/*problem4*/}
          <Col>
            <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
              <Card className={classes.dots_card}>
                <Card.Body>
                  <Col>
                    <h4>{t('welcome_problem_subtitle4')}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t(`welcome_problem_subtitle4_desc1`),
                      }}
                    />
                  </Col>
                </Card.Body>
              </Card>
            </motion.div>
          </Col>
        </Row>
      </Col>

      {/*features*/}
      <Col>
        <div className="spacer" />
        <div className="spacer" />
        <Row>
          <h1 className="h2">{t('welcome_feature_title')}</h1>
        </Row>
        <div className="spacer" />

        <Row>
          {/*feat 1*/}
          <Col>
            <Card className={classes.blue_card}>
              <Card.Body>
                <Col>
                  <h4>{t('welcome_feature_subtitle1')}</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(`welcome_feature_subtitle1_desc1`),
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(`welcome_feature_subtitle1_desc2`),
                    }}
                  />
                </Col>
              </Card.Body>
            </Card>
          </Col>

          {/*feat2*/}
          <Col>
            <Card className={classes.rainbow_card1}>
              <Card.Body>
                <Col>
                  <h4>{t('welcome_feature_subtitle2')}</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(`welcome_feature_subtitle2_desc1`),
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(`welcome_feature_subtitle2_desc2`),
                    }}
                  />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <div className="spacer" />
        <Row>
          {/*feat3*/}
          <Col>
            <Card className={classes.rainbow_card2}>
              <Card.Body>
                <Col>
                  <h4>{t('welcome_feature_subtitle3')}</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(`welcome_feature_subtitle3_desc1`),
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(`welcome_feature_subtitle3_desc2`),
                    }}
                  />
                </Col>
              </Card.Body>
            </Card>
          </Col>
          {/*feat4*/}
          <Col>
            <Card className={classes.rainbow_card3}>
              <Card.Body>
                <Col>
                  <h4>{t('welcome_feature_subtitle4')}</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(`welcome_feature_subtitle4_desc1`),
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(`welcome_feature_subtitle4_desc2`),
                    }}
                  />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <div className="spacer" />

        <div className="spacer" />
        <div style={{}}>
          <img
            src="/pre-footer.png"
            alt="Image Alt"
            loading="lazy"
            className={classes.bg_img}
            style={{
              width: 'clamp(600px, 100vw, 1200px);',
              position: 'absolute',
              height: 'auto',
              transform: 'translate(-20%, -20%)',
            }}
          />

          {!isAuthenticated ? (
            <Row>
              {/*feat3*/}
              <Col>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.8 }}
                >
                  <Card className={classes.dots_card}>
                    <Button
                      variant="primary"
                      onClick={() => {
                        loginWithRedirect();
                      }}
                    >
                      <Icon.PersonCircle style={{ fontSize: '0.8em' }} />{' '}
                      {t('welcome_to_login')}
                    </Button>
                  </Card>
                </motion.div>
              </Col>
            </Row>
          ) : null}

          <div className="spacer" />
          <h2>{t('welcom_need_help')}</h2>

          <Row>
            {/* join us */}
            <Col>
              <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <Card className={classes.blur_bg_card}>
                  <Card.Body>
                    <Col>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t(`welcome_join_community`),
                        }}
                      />
                      <Button
                        style={{
                          alignSelf: 'right',
                        }}
                        onClick={() => {
                          // to open link: https://t.me/scitudy_community
                          window.open(
                            'https://t.me/scitudy_community',
                            '_blank'
                          );
                        }}
                      >
                        <Icon.Telegram style={{ fontSize: '1em' }} />{' '}
                        {'Telegram'}
                      </Button>
                    </Col>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
            <Col>
              <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <Card className={classes.blur_bg_card}>
                  <Card.Body>
                    <Col>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t(`welcome_contact_us_tips`),
                        }}
                      />
                      <Button
                        style={{
                          alignSelf: 'right',
                        }}
                        onClick={() => {
                          window.open('mailto:admin@scitudy.com', '_blank');
                        }}
                      >
                        {' '}
                        {t('welcome_contact_more')}
                      </Button>
                    </Col>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
          </Row>

          <Footer />
        </div>
      </Col>
    </Container>
  );
}

export default Welcome;
