import { Container, Row, Col, Stack, Image, NavLink } from 'react-bootstrap';
import React from 'react';
// import { FaLanguage } from 'react-icons/fa';
// import Navbar from 'react-bootstrap/Navbar';
// import ListGroup from 'react-bootstrap/ListGroup';
// import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <hr className="hr hr-blurry" />
      <Container fluid>
        <Row className="text-white">
          <Col md={4}>
            <Stack>
              <Image
                src="/avatar.png"
                alt="company logo"
                rounded
                width={80}
                height={80}
              />
              <h2>Scitudy</h2>
              <p>Learning in Context and AI.</p>
            </Stack>
          </Col>
          <Col md={4}></Col>
          <Col md={4} className="d-flex flex-column justify-content-around">
            <div className="d-flex flex-row-reverse">
              <NavLink
                href="https://github.com/scitudyTeam/"
                className="text-white"
              >
                Team
              </NavLink>
              {'  •  '}
              <NavLink
                href="https://blog.scitudy.com/download"
                className="text-white"
              >
                Download
              </NavLink>
              {'  •  '}
              <NavLink href="https://blog.scitudy.com" className="text-white">
                Blog
              </NavLink>
              {' • '}
              <NavLink
                href="https://www.scitudy.com/privacy-policy"
                className="text-white"
              >
                Privacy
              </NavLink>
              {' • '}
              <NavLink
                href="https://blog.scitudy.com/function-introduction/"
                className="text-white"
              >
                Functions
              </NavLink>
            </div>
            <div className="d-flex flex-row-reverse">
              {'Copyright © 2024 Scitudy'}
            </div>

            {/* <Nav className="flex-column fs-5">
              Useful Links
              <NavLink href="/" className="text-white">Home</NavLink> <NavLink href="#" className="text-white">About</NavLink>
              <NavLink href="/" className="text-white">Products</NavLink>
            </Nav > */}
          </Col>
          <Col>{''}</Col>
        </Row>
      </Container>
    </footer>
  );
}
export default Footer;
