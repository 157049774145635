/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import styles from './Clips.module.css';
import { checkArrType } from '../../../common/utils/FrontendTypeCheckers';
import { useTranslation } from 'react-i18next';

export const Shortcut = (props) => {
  const { t } = useTranslation();
  const { shortcuts } = props;

  checkArrType(shortcuts, 'keys');
  if (shortcuts.length === 0) return <div>待开发</div>;

  // put two keys in one line.
  const lines = [];
  for (let i = 0; i < shortcuts.length; i += 2) {
    const line = { left: null, right: null };

    line.left = shortcuts[i];
    line.right = shortcuts[i + 1];

    lines.push(line);
  }
  if (lines.length == 0)
    throw new Error(
      'lines.length is unexpected, this must because of code bug.'
    );

  const parseKeyData2UI = (keyData) => {
    if (!keyData) throw new Error('keyData is null or undefined.');
    const { keys, desc } = keyData;
    checkArrType(keys);
    if (keys.length === 0 || keys.length > 2)
      throw new Error('keys.length is unexpected.');
    return (
      <Col md={6} style={{ fontSize: '0.8em' }}>
        <p>
          <span className={styles.key_desc}>{desc}:</span>

          {keys.map((key, index) => {
            return <span className={styles.key}>{` ${key} `}</span>;
          })}
        </p>
      </Col>
    );
  };

  return (
    <Container className="m-2">
      {lines.map((line, index) => {
        const { left, right } = line;
        return (
          <Row key={`shortkey_row_${index}`}>
            {parseKeyData2UI(left)}
            {right ? parseKeyData2UI(right) : null}
          </Row>
        );
      })}
    </Container>
  );
};

Shortcut.propTypes = {
  shortcuts: PropTypes.array.isRequired,
};

import i18next from 'i18next';
const { t } = i18next;
export const FullscreenFlashcardShortcuts = [
  { desc: t('不认识'), keys: ['←'] },
  { desc: t('认识'), keys: ['→'] },
  { desc: t('前一个'), keys: ['↑'] },
  { desc: t('后一个'), keys: ['↓'] },
  { desc: t('翻转'), keys: [t('空格')] },
  { desc: t('重启'), keys: ['CTRL', 'R'] },
];

export const LearningShortcuts = [
  { desc: t('不知道'), keys: ['CTRL', 'U'] },
  { desc: t('提交'), keys: ['Enter'] },
  { desc: t('前一个'), keys: ['CTRL', '<'] },
  { desc: t('重启'), keys: ['CTRL', 'R'] },
];

export default { Shortcut, FullscreenFlashcardShortcuts, LearningShortcuts };
