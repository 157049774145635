/* eslint-disable no-unused-vars */
import React from 'react';
// import classes from './Import.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Button from 'react-bootstrap/Button';

import FontArriSel from './Recognition/FontAttriSel';
import CreateList from './CreateList';
import {
  Redirect,
  useHistory,
} from 'react-router-dom/cjs/react-router-dom.min';

import { useParams, useLocation } from 'react-router-dom';
import RoutePaths from '../../RoutePaths';

import WordsInput from './Input/WordsInput';
import PhrasesInput from './Input/PhrasesInput';
import FileUploading from './File/FileUploading';

const ImportSource = {
  UNSETLECTED: 0,
  INPUT_BOX: 1,
  IMAGE: 2,
  PDF: 3,
  FOLDER: 4,
};

import { useTranslation } from 'react-i18next';
import CustomHelmet from '../../common/ui/CustomHelmet';
function Import() {
  const { t } = useTranslation();
  const location = useLocation();
  // const { isAuthenticated, user, logout } = useAuth0();
  // const [showFontAttriSel, setShowFontAttriSel] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [importMode, setImportSource] = useState(ImportSource.UNSETLECTED);

  const { isAuthenticated, user } = useAuth0();
  const { loginWithRedirect } = useAuth0();
  const [ocrResult, setOcrResult] = useState('');

  // to read setId and type from url.
  const id = RoutePaths.getParams(location.search).setId;
  const tmpType = RoutePaths.getParams(location.search).type || 1;
  const type = parseInt(tmpType) || 1;

  const [setId, setSetId] = useState(id);
  console.log('setId: ' + setId);

  const history = useHistory();

  const creatingStudySetCallback = (setId, type) => {
    if (!setId) console.error('setId is null.');

    if (typeof setId === 'boolean') throw new Error('setId is boolean.');

    if (type < 1 || type > 2) {
      window.showErrMsg('type is wrong.');
      return;
    }
    history.push(RoutePaths.mergeImport(setId, type));
  };

  const ocrCallback = (text, words) => {
    console.log(text);
    console.log(words);
    setOcrResult(text);
    setImportSource(ImportSource.INPUT_BOX);
  };

  const loginIntercepter = () => {
    if (!isAuthenticated) {
      loginWithRedirect();
      return false;
    }
    return true;
  };

  const importingDataCallback = (list) => {
    // to do jump to study set.
    history.push(RoutePaths.SUB_HOMEWORK);
  };

  // if pager into some special condition, then jump to other pager.
  if (setId && importMode == ImportSource.UNSETLECTED) {
    console.error('setId is set but importMode isnt set.');
    // return <Redirect to={'/mine/import'} />;
  }

  useEffect(() => {
    if (importMode == ImportSource.UNSETLECTED) {
      document.title = t('选择导入方式');
    }
  }, [setId, importMode]);

  if (setId) {
    if (importMode == ImportSource.INPUT_BOX) {
      const importCpntProps = {
        setId: parseInt(setId),
        orcResult: ocrResult,
        importingDataCallback: importingDataCallback,
      };

      return type == 1 ? (
        <WordsInput
          {...importCpntProps}
          importingWordsCallback={() => {
            history.push(RoutePaths.mergeMine(RoutePaths.SUB_HOMEWORK));
          }}
        />
      ) : (
        <PhrasesInput {...importCpntProps} />
      );
    } else if (importMode == ImportSource.IMAGE) {
      return (
        <FileUploading
          setId={parseInt(setId)}
          type="image"
          ocrCallback={ocrCallback}
        />
      );
    }
  }

  if (importMode !== ImportSource.UNSETLECTED) {
    switch (importMode) {
      case ImportSource.INPUT_BOX:
        return (
          <CreateList
            type={type}
            creatingStudySetCallback={creatingStudySetCallback}
          />
        );
      case ImportSource.IMAGE:
        return (
          <CreateList
            type={type}
            creatingStudySetCallback={creatingStudySetCallback}
          />
        );

      default:
        throw new Error('importMode went wrong');
    }
  }

  return (
    <Container fluid>
      <CustomHelmet currentMeta={window.metas.import} />
      {/*导入方式 几种*/}
      <Col>
        <div className="spacer" />
        <Row>
          <h2>{type == 1 ? t('导入单词方式') : t('导入词组方式')}</h2>
        </Row>
        <div className="spacer" />
        <div className="spacer" />

        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>{t('输入框导入')}</Card.Title>
                <Card.Text>
                  <p>{t('粘贴内容到一个输入框导入。')}</p>
                  <p>{t('文本格式可以自定义。')}</p>
                </Card.Text>
                <Button
                  variant="primary"
                  onClick={() => {
                    loginIntercepter() &&
                      setImportSource(ImportSource.INPUT_BOX);
                  }}
                >
                  {t('去导入')}
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card>
              <Card.Body>
                <Card.Title>{t('图片文件导入')}</Card.Title>
                <Card.Text>
                  <p>{t('可以通过你截图的方式，截出重点词汇。')}</p>
                  <p>{t('我们帮您识别出图片中所有的需要导入的英文单词。')}</p>
                </Card.Text>
                <Button
                  variant="primary"
                  onClick={() => {
                    loginIntercepter() && setImportSource(ImportSource.IMAGE);
                  }}
                >
                  {t('去导入')}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <div className="spacer" />
        <div className="spacer" />
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>{t('PDF导入')}</Card.Title>
                <Card.Text>
                  <p>{t('您可以上传一本PDF书籍，我们帮您识别出粗体单词。')}</p>
                  <p>{t('一帮情况下加粗的字体都是重点单词/词组。')}</p>
                </Card.Text>
                <Button variant="primary" disabled={true}>
                  {t('去导入')}
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card>
              <Card.Body>
                <Card.Title>{t('文件夹导入')}</Card.Title>
                <Card.Text>
                  <p>{t('导入一个文件夹中的所有PDF,图片文件。')}</p>
                  {t('我们会自动识别出所有的英文单词。')}
                </Card.Text>
                <Button variant="primary" disabled={true}>
                  {t('去导入')}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </Container>
  );
}

export default Import;
