/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { NavLink, Redirect } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';
import classes from './Dashboard.module.css';

import { readToken } from '../../common/apis/UserInfoHelper';
import { useAuth0 } from '@auth0/auth0-react';
import Import from '../Import/Import';
import MyHomework from '../MyHomework/MyHomework';
import RoutePaths from '../../RoutePaths';
import { isPagerComplete } from '../../common/utils/WebPagerHelper';
import LeftNavbar from './LeftNavbar';
import TrashMain from '../Trash/TashMain';
import MainLibSelector from '../Import/LibsImport/MainLibSelector';
import AITeacher from '../AITeacher/AITeacher';

function Dashboard() {
  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState('');

  const { isAuthenticated, user } = useAuth0();
  const { loginWithRedirect } = useAuth0();

  // this filed is used to check if the token is valid when pathname of the pager is "/profile".
  const [tokenCheckState, setTokenCheckState] = useState(0); // [0 ,1, 2]

  // there objects below used for routing.
  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  // console.log('subpage: ' + params.subpage); // 输出当前子页面的路由参数
  // console.log('url pathname: ' + location.pathname); // 输出当前页面的路径
  // console.log('url search: ' + location.search); // 输出当前页面的路径

  // call this function here bacause auth0 has a bug, which is that it usually did not provide user info when the '/profile' is loaded.

  const subpage = params.subpage;

  const importType = RoutePaths.getParams(location.search).type;

  useEffect(() => {
    setCurrentPage(subpage);

    if (
      isPagerComplete() &&
      ![RoutePaths.SUB_IMPORT, RoutePaths.SUB_AI_TEACHER].includes(subpage)
    ) {
      if (!readToken() && !isAuthenticated) {
        loginWithRedirect();
        return;
      }
    }
  }, []);

  // if the token is invalid, then redirect to home page. or redirect to mine-page.
  if (tokenCheckState > 0) {
    console.warn('tokenCheckState: ' + tokenCheckState);
    return tokenCheckState == 1 ? (
      <Redirect to={RoutePaths.mergeMine(RoutePaths.SUB_HOMEWORK)} />
    ) : (
      <Redirect to={RoutePaths.HOME} />
    );
  }
  // in case the user is logged out, and subpage is not "import"
  // if (!isAuthenticated && subpage != RoutePaths.SUB_IMPORT) { // this is probably causes bug.
  //   return (<Redirect to="/" />);
  // }

  return (
    <Container fluid>
      <Row>
        <Col md={3}>
          {/* column left */}
          <LeftNavbar
            currentPage={currentPage}
            importType={parseInt(importType)}
          />
        </Col>

        {/* column right */}
        <Col md={9}>
          {currentPage === RoutePaths.SUB_IMPORT ? <Import /> : ''}
          {currentPage === RoutePaths.SUB_LIB_IMPORT ? <MainLibSelector /> : ''}

          {currentPage === RoutePaths.SUB_HOMEWORK ? <MyHomework /> : ''}
          {currentPage === RoutePaths.SUB_TRASH ? <TrashMain /> : ''}
          {currentPage === RoutePaths.SUB_AI_TEACHER ? <AITeacher /> : ''}
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
