export class FullscreenFlashcardSettings {
  constructor() {
    this.autoPlayAudio = true;
    this.useKeyboard = true;
    this.onlyStared = false;
    this.shuffle = false;
    this.enableMeanings = true;
    this.relateImages = false;
  }
  autoPlayAudio;
  useKeyboard;
  onlyStared;
  shuffle;
  enableMeanings;
  relateImages;
}

export default FullscreenFlashcardSettings;
