/* eslint-disable no-unused-vars */

import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { FlashcardArray } from 'react-quizlet-flashcard';
import { Link } from 'react-router-dom';

import { useHistory } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

/**
 * In this class, the plan is that this pager will contain:
 * 1. selection;
 * 2. fill-in-blank;
 * 3. audio questions;
 * 4. similar words.
 * and there is also a ResultUI.jsx to show the result of the exam.
 **/

/**
 * Options for the exercise:
 * 1. auto-play audio;
 * 2. show CN/EN meanings;
 *
 */
function ComplexExerciseUI() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [materials, setMaterials] = useState(null); // this is an array.
}
export default ComplexExerciseUI;
