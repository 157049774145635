/* eslint-disable no-unused-vars */
import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// create a bootstrap < Modal > for user to select type[1, 2], which means word or sentence.
function StudySetTypeSelModal(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);

  const [type, setType] = useState(0); // 1: word, 2: sentence

  const handleClose = () => {
    // eslint-disable-next-line react/prop-types
    props.onChose(0);
    setShow(false);
  };
  // eslint-disable-next-line react/prop-types
  const onChose = props.onChose; // call with [0, 1, 2]

  return (
    <Modal centered show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{t('学习集创建类型选择')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>选择创建单词 或 词组学习集</p>
        <Form.Group controlId="kindOfStand">
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="创建单词集"
            id="radio_bold_1"
            value={true}
            onChange={(e) => {
              e.persist();
              console.log('first: ' + e.target.value);
              setType(1);
            }}
            checked={type == 1}
          />
          <Form.Check
            type="radio"
            aria-label="radio 2"
            id="radio_bold_2"
            label="创建词组集"
            value={false}
            checked={type == 2}
            onChange={(e) => {
              e.persist();
              console.log('second: ' + e.target.value);
              setType(2);
            }}
          />
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('取消')}
        </Button>

        <Button
          disabled={type == 0}
          variant="primary"
          onClick={() => {
            setShow(false);
            onChose(type);
          }}
        >
          {t('确定')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default StudySetTypeSelModal;
