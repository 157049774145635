/* eslint-disable no-unused-vars */
import React from 'react';
import { useEffect } from 'react';
import BACKEND_URLS from '../../common/URLPaths';

import { useAuth0 } from '@auth0/auth0-react';
import {
  getWithParams,
  postWithoutAuth,
} from '../../common/apis/requestHelper';
import { useHistory } from 'react-router-dom';

import RoutePaths from '../../RoutePaths';
import { isPagerComplete } from '../../common/utils/WebPagerHelper';
import {
  readToken,
  readTokenWithDialog,
  writeToken,
  writeUser,
} from '../../common/apis/UserInfoHelper';
import Spinner from 'react-bootstrap/Spinner';

import { useTranslation } from 'react-i18next';
const LogSucHandler = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);

  const { getAccessTokenSilently, user } = useAuth0();

  if (user) {
    writeUser(user);
  }

  useEffect(() => {
    document.title = t('登陆中');
    console.warn('Profle:' + document.readyState + ' ' + location.pathname);
    // if path is "/profile", then check if the token is valid.
    if (location.pathname === RoutePaths.PROFILE && isPagerComplete()) {
      setTimeout(async () => {
        let token = await readTokenWithDialog(getAccessTokenSilently);
        writeToken(token);
        // eslint-disable-next-line no-unused-vars
        getWithParams(BACKEND_URLS.TOKEN, token, {}, 6000)
          .then((response) => {
            writeToken(token);
            // 处理成功响应
            history.replace('/mine/' + RoutePaths.SUB_HOMEWORK);
          })
          .catch((error) => {
            console.error('user is not authenticated be our backend api.');
            console.error(error);

            if ('ECONNABORTED' == error.code) {
              window.showErrorModal(t('网络超时,请刷新页面'));
            } else {
              window.showErrorModal(t('请刷新页面, 或者重新登陆'));
            }
          });
      }, 2500); // wait for 1.5s to get the token.
    }
  }, []);

  // return Pinder
  return (
    <div className="container">
      <div className="row">
        <div className="d-flex align-items-center  justify-content-center">
          {t('正在验证') + ' token...'}
        </div>
      </div>
      <br />
      <div className="row">
        <div className="d-flex align-items-center  justify-content-center">
          {loading ? (
            <div>
              <Spinner animation="grow" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LogSucHandler;
