import { LearningRecordManager } from './LearningRecordManager';

// in thisl file, I will return manager about recording sentences, words, phrases.

const PREFIX_SENTENCE_CACHE = 'sentence_';
let sentenceCacheManager;
const PREFIX_WORD_CACHE = 'word_';
let wordCacheManager;
const PREFIX_PHRASE_CACHE = 'phrase_';
let phraseCacheManager;

export function getPhraseCacheManager() {
  if (!phraseCacheManager) {
    phraseCacheManager = new LearningRecordManager(PREFIX_PHRASE_CACHE);
  }
  return phraseCacheManager;
}

export function getSentenceCacheManager() {
  if (!sentenceCacheManager) {
    sentenceCacheManager = new LearningRecordManager(PREFIX_SENTENCE_CACHE);
  }
  return sentenceCacheManager;
}

export function getWordRecordCacheManager() {
  if (!wordCacheManager) {
    wordCacheManager = new LearningRecordManager(PREFIX_WORD_CACHE);
  }
  return wordCacheManager;
}

export default {
  getPhraseCacheManager,
  getSentenceCacheManager,
  getWordRecordCacheManager,
};
