/* eslint-disable no-unused-vars */
import React, { useRef, useEffect } from 'react';
// import classes from './Import.module.css';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Spinner from 'react-bootstrap/Spinner';
//import infinite list.
import 'react-virtualized/styles.css';
import { List, AutoSizer } from 'react-virtualized';

import { useState } from 'react';
// import { useAuth0 } from '@auth0/auth0-react';
import CloseButton from 'react-bootstrap/CloseButton';
import Button from 'react-bootstrap/Button';
import { useAuth0 } from '@auth0/auth0-react';

import { post } from '../../../common/apis/requestHelper';

import PropTypes from 'prop-types';

import {
  extractEnglishWords,
  addBlock,
} from '../../../common/utils/WordHelper.js';
import { propTypes } from 'react-bootstrap/esm/Image';

import MsgModal from '../../../common/ui/MsgModal';
import BACKEND_URLS from '../../../common/URLPaths';
import { isPagerComplete } from '../../../common/utils/WebPagerHelper';
import { useTranslation } from 'react-i18next';

const MAX_IMPORTED_ONETIME = 100;

//extracted words.
const extractedWords = [];
let wordCheckerTimer = null;
function WordsInput(props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [overSize, setOversize] = useState(false);

  let setId = props.setId;
  const orcResult = props.orcResult || '';
  if (typeof setId == 'string') setId = parseInt(setId);

  const listRef = useRef(null);
  const inputRef = useRef(null);

  const [extractedSize, setExtractedSize] = useState(0);

  const [illegalWords, setIleagalWords] = useState([]);

  const maxHeight = window.innerHeight - 120;
  useEffect(() => {
    if (!setId) alert('setId is null');

    // to clear extractedWords
    extractedWords.splice(0, extractedWords.length);

    if (!setId) window.showModal('数据出错', 'setId is null\n请刷新重试');

    if (isPagerComplete() && orcResult) {
      inputRef.current.value = orcResult;
      onInputChange(orcResult);
    }
  }, []);

  const onClickDel = (index) => {
    addBlock(extractedWords[index]);
    extractedWords.splice(index, 1);
    setExtractedSize(extractedWords.length - 1);
  };

  const rowRenderer = ({
    key, // 唯一值
    index, // 索引号
    isScrolling, // 是否在滚动中
    isVisible, // 当前行在list中是否可见
    style, // 每行的样式对象
  }) => {
    const word = extractedWords[index];
    const isIllegal = illegalWords.indexOf(word) > -1;
    if (word === undefined)
      throw new Error(
        `word is undefined at ${index} of list: ${JSON.stringify(
          extractedWords
        )}`
      );

    return (
      <div
        key={'wordinput_rightlist_row_' + key}
        style={style}
        className={isIllegal ? 'text-danger' : null}
      >
        <div className="float-start">
          {index + 1}. {word}{' '}
        </div>{' '}
        <div className="float-end">
          {' '}
          <CloseButton
            onClick={() => {
              onClickDel(index);
            }}
          />{' '}
        </div>
      </div>
    );
  };

  const checkWords = async () => {
    post(BACKEND_URLS.CHECK_WORDS, { words: extractedWords })
      .then((res) => {
        if (res.code === 0) {
          setIleagalWords(res.data);
        } else {
          console.log('checkWords failed.');
          setIleagalWords([]);
        }
        setOversize(extractedSize - illegalWords.length > MAX_IMPORTED_ONETIME);
      })
      .catch((err) => {
        console.error(err);
        setIleagalWords([]);
        setOversize(extractedSize - illegalWords.length > MAX_IMPORTED_ONETIME);
      });
  };

  const handleInputChange = (event, value) => {
    let fieldVal = value || event.target.value;
    onInputChange(fieldVal);
  };

  const onInputChange = (fieldVal) => {
    if (!fieldVal) throw new Error('fieldVal is null');

    console.log('handleInputChange ' + fieldVal);
    let words = extractEnglishWords(fieldVal);
    // to clear extractedWords.
    extractedWords.splice(0, extractedWords.length);

    for (var i = 0; i < words.length; i++) {
      const word = words[i];
      if (word && word.length >= 2) {
        if (
          !['vi', 'vt', 'adj', 'adv', 'abbr', 'conj', 'prep'].includes(
            word.toLowerCase()
          )
        ) {
          extractedWords.push(word.toLowerCase());
        }
      }
    }

    setExtractedSize(extractedWords.length);
    setOversize(extractedWords.length - illegalWords > MAX_IMPORTED_ONETIME);

    wordCheckerTimer && clearTimeout(wordCheckerTimer);
    wordCheckerTimer = setTimeout(async () => {
      await checkWords();
    }, 1200);

    listRef.current.forceUpdateGrid();
  };

  const onSubmitWords = async (event) => {
    console.log('onSubmitWords');
    const inputtedWords = event.target.elements['form.inputtedWords'].value;
    console.log(inputtedWords);
    const inputtedStr = Array.isArray(inputtedWords)
      ? JSON.stringify(inputtedWords)
      : inputtedWords;

    if (!setId || typeof setId == 'boolean') alert('setId is wrong');

    if (!extractedWords || extractedWords.length < 1) alert('setId is null');

    setIsLoading(true);
    post(BACKEND_URLS.IMPORT_WORDS_BY_INPUTBOX, {
      setId: setId,
      words: extractedWords,
      originalText: inputtedStr,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.code === 0) {
          console.log('import words success.');

          window.showSucModal(
            `${t('msg_of_importing_words', { num: res.data.insertedLen })} ${
              res.data.hasDuplicated ? t('部分词组因已存在而被忽略。') : ''
            }`,
            () => {
              // this callback may lead pager to do jump to study set.
              props.importingWordsCallback &&
                props.importingWordsCallback(res.data);
            }
          );
        } else {
          window.showErrorModal(t('导入失败,请稍后再试。'));
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        window.showErrorModal(t('导入失败,请稍后再试。'));
      });
  };

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        setTimeout(async () => {
          await onSubmitWords(event);
        });
      }}
    >
      <Container>
        <h3>{t('手动输入')}</h3>
        <div className="spacer" />
        <Col>
          <Row>
            <Col>{t('请输入')}</Col>
            <Col>
              {extractedSize > 0 ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('result_input_recognition', {
                      num: extractedSize,
                      num2: illegalWords.length,
                    }),
                  }}
                />
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            {/* left input box */}

            <Col>
              <Card>
                <Form.Group controlId="form.inputtedWords">
                  <Form.Control
                    style={{ height: maxHeight - 180 }}
                    as="textarea"
                    rows="10"
                    type="desc"
                    ref={inputRef}
                    placeholder={t(
                      '请粘贴/或者输入单词列表，支持包含中文，音标，各种符号等。'
                    )}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Card>
            </Col>

            {/* right list to draw words */}
            <Col className="card">
              {extractedWords ? (
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      key={Math.random()}
                      ref={listRef}
                      height={height}
                      width={width}
                      rowCount={extractedSize}
                      rowHeight={50} //高度是item-name的css高度
                      rowRenderer={rowRenderer}
                    />
                  )}
                </AutoSizer>
              ) : null}
            </Col>
          </Row>
        </Col>
        <div className="spacer" />

        {overSize ? (
          <div className="text-danger">
            {t('input_limit_msg', { num: MAX_IMPORTED_ONETIME })}
          </div>
        ) : null}

        <Button
          variant="primary"
          type="submit"
          disabled={isLoading || overSize}
        >
          {isLoading ? (
            <div>
              {t('提交')} <Spinner animation="border" size="sm" />
            </div>
          ) : (
            t('提交')
          )}
        </Button>
      </Container>
    </Form>
  );
}

WordsInput.propTypes = {
  setId: PropTypes.number.isRequired,
  orcResult: PropTypes.string,
  importingWordsCallback: PropTypes.func,
};

export default WordsInput;
