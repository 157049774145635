/* eslint-disable no-unused-vars */
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

import PropTypes from 'prop-types';

import {
  get,
  post,
  showingResponseError,
} from '../../../common/apis/requestHelper';

import styles from './StudySet.module.css';
import Spinner from 'react-bootstrap/Spinner';
// card
import Card from 'react-bootstrap/Card';

import { useHistory } from 'react-router-dom';

//import infinite list.
import 'react-virtualized/styles.css';
import Container from 'react-bootstrap/Container';
import { Grid } from 'react-virtualized';
import { List, AutoSizer } from 'react-virtualized';

// import auth0
import { useAuth0 } from '@auth0/auth0-react';
import { isPagerComplete } from '../../../common/utils/WebPagerHelper';
import {
  readToken,
  readTokenWithDialog,
  readUser,
  writeUser,
} from '../../../common/apis/UserInfoHelper';
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';

import RoutePaths from '../../../RoutePaths';
import BACKEND_URLS from '../../../common/URLPaths';
import {
  checkArrType,
  checkNonEmptyArray,
} from '../../../common/utils/FrontendTypeCheckers';

let GRID_COLUMN_COUNT = 3;

const itemW = 320;
const itemH = 190;

import { useTranslation } from 'react-i18next';

// to draw
const StudySetList = (props) => {
  const { t } = useTranslation();
  const maxHeight = window.innerHeight - 90;

  const listRef = useRef(null);
  const containerRef = useRef(null);
  const [isRequesting, setIsRequesting] = useState(true);
  const [countOfLists, setCountOfLists] = useState(0);
  const [myStudysets, setMyStudysets] = useState([]);

  const history = useHistory();
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();

  const type = props.type; // 1: word, 2: sentence

  if (user?.id) {
    writeUser(user);
  }

  useEffect(() => {
    if (!isPagerComplete()) {
      return;
    }

    const w = document.getElementById('my-studyset-container').clientWidth;
    GRID_COLUMN_COUNT = Math.floor(w / 314);
    GRID_COLUMN_COUNT = GRID_COLUMN_COUNT > 0 ? GRID_COLUMN_COUNT : 1;

    // get my study set.
    setTimeout(async () => {
      const showErrorModal = window.showErrorModal;
      if (!type) throw new Error('type is not defined');

      const url =
        type == 1
          ? BACKEND_URLS.MY_STUDYSET_PATH
          : BACKEND_URLS.MY_PHRASE_SETS_PATH;
      post(url)
        .then((res) => {
          if (!res) return; // null maybe come from axios-retry.
          if (res.code === 0) {
            myStudysets.slice(0, myStudysets.length);
            checkArrType(res.data);

            const arr = res.data;

            for (let i = 0; i < arr.length; i++) {
              myStudysets.push(arr[i]);
            }
            setMyStudysets(myStudysets);
            setCountOfLists(myStudysets.length);
            console.log('StudySetList -> myStudysets :' + countOfLists);
          } else {
            console.error('http res: ' + res);
            console.error('http code: ' + res.httpCode);
            !showingResponseError &&
              showErrorModal(t('数据异常，请稍后重试。'));
          }
        })
        .catch((error) => {
          !showingResponseError && showErrorModal(t('数据异常，请稍后重试。'));
          console.error('StudySetList failed2.');
          console.error(error);
        })
        .finally(() => {
          setIsRequesting(false);
        });
    }, 100);
  }, [myStudysets]);

  if (isRequesting) {
    // doc: https://react-bootstrap.netlify.app/docs/components/spinners/

    return (
      <Container
        className={styles.studyset_container}
        ref={containerRef}
        id="my-studyset-container"
        style={{ flex: '1 1 auto', minHeight: 800 }}
      >
        <div className="d-flex align-items-center  justify-content-center">
          <Spinner animation="grow" />
        </div>
      </Container>
    );
  }

  // in case of empty list.
  if (myStudysets.length === 0) {
    return (
      <div
        className="d-flex align-items-center  justify-content-center"
        style={{ height: maxHeight, fontSize: '1.3em' }}
      >
        <p>{t('您还没有创建学习集。请点击右上角➕进行导入操作。')}</p>
      </div>
    );
  }
  const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    const index = rowIndex * GRID_COLUMN_COUNT + columnIndex;
    if (index >= countOfLists) return null;

    const { countOfGenerated, countOfImported } = myStudysets[index];
    const diff = countOfImported - countOfGenerated;
    const isAIGenerating = diff > 0;
    const generationRatio = type == 2 ? 0.2 : 0.3;

    return (
      <div key={type + key + '_child_div_' + index}>
        <Card
          className={
            isAIGenerating ? 'bg-secondary text-muted' : styles.studyset_card
          }
          aria-disabled={isAIGenerating}
          key={type + key + 'card_' + index}
          style={{
            ...style,
            margin: '10px',
            width: itemW - 20 + 'px',
            height: itemH - 20 + 'px',
          }}
        >
          <Card.Body
            onClick={() => {
              history.push(RoutePaths.mergeList(myStudysets[index].id));
            }}
          >
            <div style={{ padding: '1em' }}>
              <Col>
                <Row>
                  <h4>{myStudysets[index].listName}</h4>
                </Row>
                <Col className="float-end">
                  <div>
                    <p>
                      {type == 1 ? t('单词数：') : t('词组数：')}
                      {myStudysets[index].countOfImported}
                    </p>
                  </div>
                  <div>
                    {diff > 0 ? (
                      <p className="text-warning">
                        {t('AI生成中,预估等待')}{' '}
                        {(diff * generationRatio).toFixed(1)} {t('分钟')}
                      </p>
                    ) : (
                      <p>{t('学习中')}</p>
                    )}
                  </div>
                </Col>
              </Col>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  };

  return (
    <Container
      className={styles.studyset_container}
      ref={containerRef}
      id="my-studyset-container"
      style={{ flex: '1 1 auto', minHeight: 800 }}
    >
      <AutoSizer>
        {({ height, width }) => (
          <Grid
            ref={listRef}
            key={'my-vocab-grid-' + type}
            className="justify-content-between"
            cellRenderer={cellRenderer}
            columnCount={GRID_COLUMN_COUNT}
            rowCount={countOfLists / GRID_COLUMN_COUNT + 1}
            columnWidth={itemW}
            rowHeight={itemH}
            width={width}
            height={height}
            style={{}}
          />
        )}
      </AutoSizer>
    </Container>
  );
};

StudySetList.propTypes = {
  type: PropTypes.number.isRequired,
};
export default StudySetList;
