import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';
import classes from './Dashboard.module.css';
import RoutePaths from '../../RoutePaths';

import { useTranslation } from 'react-i18next';

const LeftNavbar = (props) => {
  const { t } = useTranslation();
  const { currentPage, importType } = props;

  return (
    <Card key="left_nav_bar">
      <Card.Body>
        <h3>Dashboard</h3>
        <ListGroup key="dashboard-left-nav">
          <Link
            to={RoutePaths.mergeMine(RoutePaths.SUB_HOMEWORK)}
            className={
              currentPage === RoutePaths.SUB_HOMEWORK
                ? classes.active_left_item
                : classes.left_item
            }
          >
            <ListGroup.Item>{t('我的学习集')}</ListGroup.Item>
          </Link>
          <Link
            to={RoutePaths.mergeMine(RoutePaths.SUB_IMPORT) + '?type=1'}
            className={
              currentPage === RoutePaths.SUB_IMPORT && importType == 1
                ? classes.active_left_item
                : classes.left_item
            }
          >
            <ListGroup.Item>{t('导入生词')}</ListGroup.Item>
          </Link>
          <Link
            to={RoutePaths.mergeMine(RoutePaths.SUB_IMPORT) + '?type=2'}
            className={
              currentPage === RoutePaths.SUB_IMPORT && importType == 2
                ? classes.active_left_item
                : classes.left_item
            }
          >
            <ListGroup.Item>{t('导入词组')}</ListGroup.Item>
          </Link>

          <NavLink
            to={RoutePaths.mergeMine(RoutePaths.SUB_LIB_IMPORT)}
            className={
              currentPage === RoutePaths.SUB_LIB_IMPORT
                ? classes.active_left_item
                : classes.left_item
            }
          >
            <ListGroup.Item>{t('从词库导入')}</ListGroup.Item>
          </NavLink>

          <NavLink
            to={RoutePaths.mergeMine(RoutePaths.SUB_AI_TEACHER)}
            className={
              currentPage === RoutePaths.SUB_AI_TEACHER
                ? classes.active_left_item
                : classes.left_item
            }
          >
            <ListGroup.Item>{t('AI老师')}</ListGroup.Item>
          </NavLink>

          <ListGroup.Item disabled>{t('我的收藏')}</ListGroup.Item>

          <Link
            to={RoutePaths.mergeMine(RoutePaths.SUB_TRASH)}
            className={
              currentPage === RoutePaths.SUB_TRASH
                ? classes.active_left_item
                : classes.left_item
            }
          >
            <ListGroup.Item>{t('回收站')}</ListGroup.Item>
          </Link>
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

LeftNavbar.propTypes = {
  currentPage: PropTypes.string.isRequired,
  importType: PropTypes.number,
};
export default LeftNavbar;
