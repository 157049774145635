import {
  checkArrType,
  checkObjectType,
  checkStringType,
} from '../../../common/utils/FrontendTypeCheckers';

import { getSentenceCacheManager } from '../utils/ManagerGetter';
import { getStudySetting } from './LearningOptionsStorage';

// it should be over 5 at least.
const LEN_OF_RESULT = 30;

export const TYPE_AUDIO = 'audio';
export const TYPE_SELECTION = 'selection';
export const TYPE_BLANK = 'fill-in-blank';

/**
 * 从数据库中获取的数据，需要经过这个函数处理，才能用于前端显示。
 * 这里提取句子或者word的顺序为：
 * 1. 从48H之内的数据里取一些错过的词汇。
 * 2. 如果48H之内的数据不够，那么从其他数据里取一些词汇。
 */

/**
 * @param result be like:
 * {
 *      words; //string[]
 *      sentencesIn48Hours; //sentences[]
 *      otherSentences; //sentences[]
 * }
 * @returns Array like:[ {type: 'audio', content: string }, {type: 'selection', content: sentence}}]
 *
 * and some type in there:  'audio', 'selection', 'fill-in-blank'
 */
export function parseResult4LearningMaterials(result) {
  checkObjectType(result, 'result');

  const { shuffle, onlyStared } = getStudySetting();

  const { words, phrases, sentencesIn48Hours, otherSentences, supplements } =
    result;
  const materials = [];

  if (sentencesIn48Hours && sentencesIn48Hours.length > 0) {
    sentencesIn48Hours.forEach((sentence) => {
      if (!sentence.id) throw new Error('sentence.id is not provided.');
      if (!sentence.Word && !sentence.Phrase) return;

      // check user's setting.
      if (onlyStared && !sentence.isStared) return;
      if (getSentenceCacheManager().getFailureCount(sentence.id) > 1) {
        materials.push(createSentenceMaterial(sentence, supplements));
      } else if (getSentenceCacheManager().getSucCount(sentence.id) == 0) {
        materials.push(createSentenceMaterial(sentence, supplements));
      }
    });
    if (materials.length == 0) throw new Error('materials.length ==0');
  }

  // use this variable to limit the count of words.
  let countOfWords = 0;
  if (materials.length < LEN_OF_RESULT) {
    const listeningMaterials = [];
    words && listeningMaterials.push(...words);
    phrases && listeningMaterials.push(...phrases);
    if (listeningMaterials) {
      listeningMaterials.forEach((item) => {
        const radomInt = Math.floor(Math.random()); //put word randomly.
        if (
          countOfWords < LEN_OF_RESULT / 3 &&
          materials.length < LEN_OF_RESULT &&
          radomInt % 3 === 0
        ) {
          countOfWords++;
          materials.push({ type: TYPE_AUDIO, content: item });
        }
      });
    }
  }

  if (materials.length < LEN_OF_RESULT) {
    if (otherSentences) {
      otherSentences.forEach((sentence) => {
        // check user's setting.
        if (onlyStared && !sentence.isStared) return;
        if (materials.length < LEN_OF_RESULT) {
          materials.push(createSentenceMaterial(sentence, supplements));
        }
      });
    }
  }

  if (shuffle) {
    shuffleArray(materials);
  }

  return materials;
}

// in this function, I will return  { type: X, content: sentence},  the type would only be 'selection' or ''fill-in-blank''

/**
 *
 * @param {object} sentence
 * @param {string[]} supplements
 * @returns {object} - { type: X, content: sentence}
 */
function createSentenceMaterial(sentence, supplements) {
  checkObjectType(sentence, 'sentence');
  checkArrType(supplements, 'supplements');

  const answer = sentence.Word?.word || sentence.Phrase?.text;

  checkStringType(answer, 'answer');

  let result;
  if (
    sentence.Word != undefined &&
    supplements.length < 4 &&
    randomInt() % 3 === 0
  ) {
    result = { type: TYPE_BLANK, content: sentence };
  } else {
    const selections = [];
    shuffleArray(supplements);
    const first5Supplements = supplements.slice(0, 5).filter((item) => {
      return item != answer;
    });
    // first 3 words from supplements, and put them into selections.
    selections.push(...first5Supplements.slice(0, 3));
    const correctWord = answer;
    if (!correctWord)
      throw new Error("neither of sentence's word or phrase is not found.");

    selections.push(correctWord);
    shuffleArray(selections);
    console.log('selections', selections);
    result = { type: TYPE_SELECTION, content: sentence, selections };
  }

  return result;
}

function randomInt() {
  return Math.floor(Math.random() * 100) + 1;
}

export default {
  parseResult4LearningMaterials,
  TYPE_AUDIO,
  TYPE_SELECTION,
  TYPE_BLANK,
};

// ----------shuffleArray ------------

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}
