/* eslint-disable no-unused-vars */
import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { FlashcardArray } from 'react-quizlet-flashcard';
import { Link } from 'react-router-dom';
//import infinite list.
import 'react-virtualized/styles.css';
import { Button, Form, Spinner } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import BACKEND_URLS from '../../../common/URLPaths';
import { NavLink, Redirect } from 'react-router-dom';
import ReactCardFlip from 'react-card-flip';
import styles from './StudySet.module.css';
import { useParams, useLocation, useHistory } from 'react-router-dom';
// import auth0
import { useAuth0 } from '@auth0/auth0-react';
import { isPagerComplete } from '../../../common/utils/WebPagerHelper';
import { Card, Col, Row } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { checkBooleanType } from '../../../common/utils/FrontendTypeCheckers';
import RoutePaths from '../../../RoutePaths';
import { post, showingResponseError } from '../../../common/apis/requestHelper';
import { readUser } from '../../../common/apis/UserInfoHelper';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const StudySetEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const { setId } = params;
  if (!setId) {
    return <Redirect to={'/notfound'} />;
  }

  const [loading, setLoading] = useState(true);
  const [studySetDetail, setStudySetDetail] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [isGongingToOpen, setIsGongingToOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [schoolName, setSchoolName] = useState('');

  //delete
  const [deleting, setDeleting] = useState(false);

  const deleteStudySet = () => {
    setDeleting(true);
    post(BACKEND_URLS.STUDYSET_DELETE, {
      setId,
    })
      .then((rsp) => {
        if (rsp.code == 0) {
          window.showSucModal('删除成功', () =>
            history.push(RoutePaths.mergeMine(RoutePaths.SUB_HOMEWORK))
          );
        } else {
          !showingResponseError &&
            window.showErrorModal(rsp.message || t('删除失败'));
        }
      })
      .catch((err) => {
        console.log(err);
        !showingResponseError && window.showErrorModal(t('系统错误'));
      });
  };

  // save functions
  const [saving, setSaving] = useState(false);
  const saveStudySet = () => {
    if (!title || title.trim().length == 0) {
      window.showErrorModal(t('标题不能为空'));
      return;
    }
    setSaving(true);
    post(BACKEND_URLS.STUDYSET_UPDATE, {
      id: setId,
      data: {
        title,
        desc,
        schoolName,
        isOpen: isGongingToOpen,
      },
    })
      .then((rsp) => {
        console.log('rsp: ' + JSON.stringify(rsp));

        setSaving(false);
        if (rsp.code == 0) {
          window.showSucModal(t('保存成功'), () =>
            history.push(RoutePaths.mergeList(setId))
          );
        } else {
          !showingResponseError &&
            window.showErrorModal(rsp.message || t('保存失败'));
        }
      })
      .catch((err) => {
        setSaving(false);
        console.log(err);
        !showingResponseError && window.showErrorModal(t('系统错误'));
      });
  };

  useEffect(() => {
    setLoading(true);

    if (isPagerComplete()) {
      post(BACKEND_URLS.STUDYSET_INFO, {
        setId,
      })
        .then((rsp) => {
          console.log('rsp: ' + JSON.stringify(rsp));
          if (rsp) {
            const { data, message, code } = rsp;
            if (code == 0) {
              setIsGongingToOpen(data.isOpen);
              setDesc(data.listDesc);
              setTitle(data.listName);
              setSchoolName(data.schoolName);
              setStudySetDetail(data);
            } else {
              !showingResponseError && window.showErrorModal(message);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          !showingResponseError && window.showErrorModal(t('系统错误'));
        });
    }
  }, []);

  if (loading) {
    return (
      <Container className="d-flex align-items-center justify-content-center">
        <div>
          <Spinner animation="grow" />
          <span className="sr-only">Loading...</span>
        </div>
      </Container>
    );
  } else if (!studySetDetail) {
    // unexpected situation.
    return (
      <Container>
        <Row>
          <Col>
            <span>数据异常</span>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col md={8}>
          <h2>{t('词表信息编辑')}</h2>
        </Col>
        <Col md={4} className="d-flex align-items-end justify-content-end">
          <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
            <Button disabled={!hasChanged || saving} onClick={saveStudySet}>
              {saving ? 'waiting' : t('保存')}
            </Button>
          </motion.div>
        </Col>
      </Row>

      <hr />

      <Row className="p-2">
        <Col>
          <Form.Group controlId="form.title">
            <Form.Control
              type="title"
              placeholder={t('标题')}
              value={title}
              onChange={({ target: { value } }) => {
                setTitle(value);
                setHasChanged(true);
              }}
            />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="form.schoolname">
            <Form.Control
              type="school"
              placeholder={t('学校(可选)')}
              value={schoolName}
              onChange={({ target: { value } }) => {
                setSchoolName(value);
                setHasChanged(true);
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      <div className="spacer" />
      <Row className="p-2">
        <Form.Group controlId="form.desc">
          <Form.Control
            as="textarea"
            rows="3"
            type="desc"
            placeholder={t('描述(可选)')}
            value={desc}
            onChange={({ target: { value } }) => {
              setDesc(value);
              setHasChanged(true);
            }}
          />
        </Form.Group>
      </Row>

      <div className="spacer" />

      <h3>{t('危险操作')}</h3>
      <hr />

      <Row className="p-2" style={{ fontSize: '1.2em' }}>
        <div className="d-flex flex-row">
          <div>{t('公开词表：')}</div>
          <div>
            <Form.Check
              checked={isGongingToOpen}
              onChange={({ target: { checked } }) => {
                checkBooleanType(checked);
                setIsGongingToOpen(checked);
                setHasChanged(true);
              }}
              type="switch"
            />
          </div>
        </div>
      </Row>

      {isGongingToOpen ? (
        <Row className="p-2">
          <p className="text-warning p-2">
            {t(
              '我们只会公开您的昵称，其他涉及到个人隐私信息比如：email将依旧保持隐藏。'
            )}
          </p>
        </Row>
      ) : null}
      <Row className="p-2">
        <div className="d-flex flex-row">
          <div style={{ fontSize: '1.2em' }}>{t('删除：')}</div>
          <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
            <Button
              variant="danger"
              disabled={deleting}
              onClick={deleteStudySet}
            >
              {deleting ? 'waiting' : t('删除本词表')}
            </Button>
          </motion.div>
        </div>
      </Row>
    </Container>
  );
};

export default StudySetEdit;
