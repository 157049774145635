import React from 'react';
import { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTranslation } from 'react-i18next';

import DeletedStudySets from './DeletedStudySets';

function TrashMain() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('回收站');
  }, []);

  return (
    <Tabs fill defaultActiveKey="vocab_tab">
      <Tab eventKey="vocab_tab" title={t('词表')}>
        <DeletedStudySets type={1} />
      </Tab>

      <Tab eventKey="phrase_tab" title={t('词组/固定搭配')}>
        <DeletedStudySets type={2} />
      </Tab>
    </Tabs>
  );
}

export default TrashMain;
